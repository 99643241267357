// #region IMPORTS
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_RowSelectionState,
  MRT_VisibilityState,
  MRT_ColumnOrderState,
  MRT_GroupingState,
  MRT_SortingState,
  MRT_ExpandAllButton,
  MRT_ExpandButton,
  MRT_ColumnFiltersState,
  MRT_ColumnFilterFnsState,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_FilterFn,
  MRT_Row
} from 'material-react-table';

//Material UI Imports
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  lighten,
} from '@mui/material';

//Icons Imports
import {
  AccountCircle,
  Send,
  Delete,
  ContentCopy,
  Save,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
  RefreshOutlined,
  Download,
  Map,
  Check,
  MoreHoriz
} from '@mui/icons-material';
import { useSearchParams } from "react-router-dom";

import { ATRecord, Jobs, getField, Companies, update, getFieldId, Branches, Contacts } from '@rogoag/airtable';
import { getBoundaryForJob, getUsersData, getPointsForJob, getBranches } from '../api/airtable_ops';
import { UserSettings, ViewCollection, ViewDefinition, ViewGroup } from '../types';
import { checkboxColumn, checkboxMultiselectColumn, dateColumn, fileColumn, multiSelectColumn, numericColumn, textColumn } from './MRT_AirTableHelpers';
import { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import { UserContext } from '../hooks/UserContext';
import ViewSaveDialog from './ViewSaveDialog';
import AirtableRecordSelectionAutocomplete from './AirtableRecordSelectionAutocomplete';
import { toast } from 'react-toastify';
import { sleep } from '../utils';
//import useUrlState from '@ahooksjs/use-url-state';
import { MarkJobReadyPopover } from './MarkJobReadyPopover';
import { LoadingComponent } from './LoadingComponent';
import { Props as ViewSaveDialogProps } from './ViewSaveDialog';

import * as Sentry from '@sentry/react';
import Airtable, { Attachment } from 'airtable';
import JSZip from 'jszip';
import React from 'react';
import { RowSelectionState } from '@tanstack/table-core';
import PortalTooltip from './PortalTooltip';
import * as API from '../API';
// #endregion

// #region FIELD NAMES
class FieldNames {
  columnLabel: string;
  airtable: string;
  columnName() {
    return this.columnLabel.toLowerCase();
  }

  constructor(display: string, airtable: string = "") {
    this.columnLabel = display;
    if (airtable !== "") {
      this.airtable = airtable;
    } else {
      this.airtable = display;
    }
  }
}

const N_Grower = new FieldNames("Grower");
const N_Field = new FieldNames("Field", "Field Name Clean");
const N_Farm = new FieldNames("Farm", "Farm Name Clean");
const N_JobStatus = new FieldNames("Job Status", "Job Status - Simple");
const N_TestPackages = new FieldNames("Test Package", "Test Pckg - Display");
const N_Submitter = new FieldNames("Submitter");
const N_Season = new FieldNames("Season");
const N_Branch = new FieldNames("Branch", "Branch / Location #form");
const N_BoundaryAcres = new FieldNames("Boundary Acres");
const N_FieldReadyDate = new FieldNames("Field Ready Date");
const N_SampleDate = new FieldNames("Sample Date");
const N_CreationDate = new FieldNames("Creation Date", "Creation Date with Time");
const N_DropShipDate = new FieldNames("Drop/Ship Date");
const N_BoundaryChange = new FieldNames("Boundary Change?", "Bdy Change?");
const N_BoundaryApproved = new FieldNames("Boundary Approved?", "Bdy Approved for Send?");
const N_PointsChange = new FieldNames("Points Change?", "Pts Created/Edited?");
const N_ApprovedBilling = new FieldNames("Approved Billing?", "$ Approved Billing?");
const N_Boundary = new FieldNames("Boundary", "Bnd Shp");
const N_Points = new FieldNames("Points", "Pts Shp");
const N_SampleZones = new FieldNames("Sample Zones", "Sample Zones Shp");
const N_ExecutedPoints = new FieldNames("Executed Points", "Exe Pts Shp");
const N_ExecutedBoundary = new FieldNames("Executed Boundary", "Exe Bnd Shp");
const N_ExecutedPointsBoundary = new FieldNames("Executed Points + Boundary", "Exe Pts + Bnd Shps");
const N_LabSentResults = new FieldNames("Lab Sent Results", "Lab Results Sent Date");
const N_LabResults = new FieldNames("Lab Results");
const N_SamplingCSV = new FieldNames("Sampling CSV", "CSV checkin - lab");
const N_EventID = new FieldNames("Event ID");
const N_LabSubmittalID = new FieldNames("Lab Submittal ID", "Regular Lab Submission Code");
const N_Depth = new FieldNames("Depth");
const N_SampleChanges = new FieldNames("Sample Changes");

const FILE_COLUMNS = [
  N_Points,
  N_Boundary,
  N_SampleZones,
  N_ExecutedPoints,
  N_ExecutedBoundary,
  N_ExecutedPointsBoundary,
  N_LabResults,
  N_SamplingCSV,
]
// #endregion

// #region FILTERS / VIEWS
const DEFAULT_FILTER_FNS: MRT_ColumnFilterFnsState = {
  // [N_SampleDate.columnName()]: 'greaterThanOrEqualTo',
  // [N_FieldReadyDate.columnName()]: 'greaterThanOrEqualTo',
  [N_BoundaryAcres.columnName()]: 'between',
  [N_JobStatus.columnName()]: 'arrIncludes',
  [N_Field.columnName()]: 'fuzzy',
  [N_Farm.columnName()]: 'fuzzy',
  // [N_CreationDate.columnName()]: 'greaterThanOrEqualTo',
  // [N_DropShipDate.columnName()]: 'greaterThanOrEqualTo',
  [N_TestPackages.columnName()]: 'arrIncludes',
  'edits': 'fuzzy',
  'files last edited?': 'greaterThanOrEqualTo',
  [N_Submitter.columnName()]: 'fuzzy',
  [N_Branch.columnName()]: 'fuzzy',
  'map issue': 'equals',
  // [N_LabSentResults.columnName()]: 'greaterThanOrEqualTo',
  [N_BoundaryApproved.columnName()]: 'equals',
  [N_BoundaryChange.columnName()]: 'equals',
  [N_PointsChange.columnName()]: 'equals',
  [N_Season.columnName()]: 'arrIncludes',
} as const;

const DEFAULT_FILTERS: MRT_ColumnFiltersState = [];
const DEFAULT_GROUPING: MRT_GroupingState = [N_Grower.columnName()];
const DEFAULT_COLUMN_SORT: MRT_SortingState = [];
const DEFAULT_VIS_STATE: MRT_VisibilityState = {};
const DEFAULT_COLUMN_ORDER: MRT_ColumnOrderState = [
  "mrt-row-select",
  "mrt-row-expand",
  N_Grower.columnName(),
  N_Field.columnName(),
  N_Farm.columnName(),
  N_JobStatus.columnName(),
  N_BoundaryAcres.columnName(),
  N_FieldReadyDate.columnName(),
  N_SampleDate.columnName(),
  N_CreationDate.columnName(),
  N_DropShipDate.columnName(),
  N_TestPackages.columnName(),
  "edits",
  "files last edited?",
  N_Submitter.columnName(),
  N_ApprovedBilling.columnName(),
  N_Boundary.columnName(),
  N_Points.columnName(),
  N_SampleZones.columnName(),
  N_ExecutedPoints.columnName(),
  N_ExecutedBoundary.columnName(),
  N_ExecutedPointsBoundary.columnName(),
  N_LabSentResults.columnName(),
  N_LabResults.columnName(),
  N_Branch.columnName(),
  "job flags",
  N_LabSentResults.columnName(),
]

const PARAM_SEPARATOR = '|';

const dashboardStateToURLParams = (state: DashboardURLState): URLSearchParams => {
  const params = new URLSearchParams()
  const {
    columnVisibility,
    sorting,
    grouping,
    columnOrder,
    columnFilterFunctions,
    columnFilterValues
  } = state;
  const urlSorting = sorting.map(sort => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`).join(PARAM_SEPARATOR);
  const urlGrouping = grouping.join(PARAM_SEPARATOR);
  const urlColumnOrder = columnOrder.join(PARAM_SEPARATOR);
  const urlColumnVisibility = Object.keys(columnVisibility).map(key => `${key}=${columnVisibility[key]}`).join(PARAM_SEPARATOR);
  const urlFunctions = Object.keys(columnFilterFunctions).map(key => `${key}=${columnFilterFunctions[key]}`).join(PARAM_SEPARATOR);
  const urlFilterValues = columnFilterValues.map(filter => `${filter.id}:${filter.value}`).join(PARAM_SEPARATOR);

  params.set('sort', urlSorting);
  params.set('group', urlGrouping);
  params.set('vis', urlColumnVisibility);
  params.set('order', urlColumnOrder);
  params.set('fns', urlFunctions);
  params.set('filter', urlFilterValues);

  params.sort();
  return params;
}

const DEFAULT_VIEW_NAME = 'Group by Season, Branch, Grower';

const DEFAULT_VIEWS: ViewCollection = [
  {
    name: DEFAULT_VIEW_NAME,
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: false,
        [N_Branch.columnName()]: false,
        [N_Season.columnName()]: false,
      },
      grouping: [N_Season.columnName(), N_Branch.columnName(), N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Group by Status',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_JobStatus.columnName()]: false,
      },
      grouping: ['job status'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Unready Jobs',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: N_JobStatus.columnName(), value: '0. Unready' },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: true,
        [N_Field.columnName()]: true,
        [N_Farm.columnName()]: true,
        [N_JobStatus.columnName()]: true,
        [N_TestPackages.columnName()]: false,
        [N_FieldReadyDate.columnName()]: false,
        [N_SampleDate.columnName()]: false,
        [N_CreationDate.columnName()]: false,
        [N_DropShipDate.columnName()]: false,
        "test package": false,
        "edits": false,
        "files last edited?": false,
        [N_Submitter.columnName()]: false,
        [N_ApprovedBilling.columnName()]: false,
        [N_Boundary.columnName()]: false,
        [N_Points.columnName()]: false,
        [N_SampleZones.columnName()]: false,
        [N_ExecutedPoints.columnName()]: false,
        [N_ExecutedBoundary.columnName()]: false,
        [N_ExecutedPointsBoundary.columnName()]: false,
        [N_LabSentResults.columnName()]: false,
        [N_LabResults.columnName()]: false,
        [N_Branch.columnName()]: false,
        "job flags": false,
        [N_LabSentResults.columnName()]: false,
        [N_PointsChange.columnName()]: true,
        [N_BoundaryChange.columnName()]: true,
        [N_BoundaryApproved.columnName()]: true,
      },
      grouping: [N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Boundary Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: N_BoundaryChange.columnName(), value: true },
        { id: N_BoundaryApproved.columnName(), value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: true,
        [N_Field.columnName()]: true,
        [N_Farm.columnName()]: true,
        [N_JobStatus.columnName()]: true,
        [N_BoundaryAcres.columnName()]: false,
        [N_FieldReadyDate.columnName()]: false,
        [N_SampleDate.columnName()]: false,
        [N_CreationDate.columnName()]: false,
        [N_DropShipDate.columnName()]: false,
        [N_TestPackages.columnName()]: false,
        "edits": false,
        "files last edited?": false,
        [N_Submitter.columnName()]: false,
        [N_ApprovedBilling.columnName()]: false,
        [N_Boundary.columnName()]: false,
        [N_Points.columnName()]: false,
        [N_SampleZones.columnName()]: false,
        [N_ExecutedPoints.columnName()]: false,
        [N_ExecutedBoundary.columnName()]: true,
        [N_ExecutedPointsBoundary.columnName()]: true,
        [N_LabSentResults.columnName()]: false,
        [N_LabResults.columnName()]: false,
        [N_Branch.columnName()]: false,
        "job flags": false,
        [N_LabSentResults.columnName()]: false,
        [N_PointsChange.columnName()]: true,
        [N_BoundaryChange.columnName()]: true,
        [N_BoundaryApproved.columnName()]: true,
      },
      grouping: [N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Points Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: N_PointsChange.columnName(), value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: true,
        [N_Field.columnName()]: true,
        [N_Farm.columnName()]: true,
        [N_JobStatus.columnName()]: true,
        [N_BoundaryAcres.columnName()]: false,
        [N_FieldReadyDate.columnName()]: false,
        [N_SampleDate.columnName()]: false,
        [N_CreationDate.columnName()]: false,
        [N_DropShipDate.columnName()]: false,
        [N_TestPackages.columnName()]: false,
        "edits": false,
        "files last edited?": false,
        [N_Submitter.columnName()]: false,
        [N_ApprovedBilling.columnName()]: false,
        [N_Boundary.columnName()]: false,
        [N_Points.columnName()]: false,
        [N_SampleZones.columnName()]: false,
        [N_ExecutedPoints.columnName()]: true,
        [N_ExecutedBoundary.columnName()]: false,
        [N_ExecutedPointsBoundary.columnName()]: true,
        [N_LabSentResults.columnName()]: false,
        [N_LabResults.columnName()]: false,
        [N_Branch.columnName()]: false,
        "job flags": false,
        [N_LabSentResults.columnName()]: false,
        [N_PointsChange.columnName()]: true,
        [N_BoundaryChange.columnName()]: true,
        [N_BoundaryApproved.columnName()]: true,
      },
      grouping: [N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  }
];

interface DashboardURLState {
  sorting: MRT_SortingState;
  grouping: MRT_GroupingState;
  columnOrder: MRT_ColumnOrderState;
  columnVisibility: MRT_VisibilityState;
  columnFilterFunctions: MRT_ColumnFilterFnsState;
  columnFilterValues: MRT_ColumnFiltersState;
}


const urlParamsToDashboardState = (urlParams: URLSearchParams): DashboardURLState => {
  const rawSortParam = urlParams.get('sort');
  const rawGroupParam = urlParams.get('group');
  const rawOrderParam = urlParams.get('order');
  const rawVisParam = urlParams.get('vis');
  const rawFnsParam = urlParams.get('fns');
  const rawFilterParam = urlParams.get('filter');
  const columnFilterValues = rawFilterParam ? rawFilterParam.split(PARAM_SEPARATOR).map(filter => ({ id: filter.split(':')[0], value: filter.split(':')[1] })) : [];
  const viewState: DashboardURLState = {
    sorting: rawSortParam ? rawSortParam.split(PARAM_SEPARATOR).map(sort => ({ id: sort.split(':')[0], desc: sort.split(':')[1] === 'desc' })) : [],
    grouping: rawGroupParam ? rawGroupParam.split(PARAM_SEPARATOR) : [],
    columnOrder: rawOrderParam ? rawOrderParam.split(PARAM_SEPARATOR) : [],
    columnVisibility: rawVisParam ? rawVisParam.split(PARAM_SEPARATOR).reduce((acc: MRT_VisibilityState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value === 'true';
      return acc;
    }, {}) : {},
    columnFilterFunctions: rawFnsParam ? rawFnsParam.split(PARAM_SEPARATOR).reduce((acc: MRT_ColumnFilterFnsState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value;
      return acc;
    }, {}) : {},
    columnFilterValues
  };
  return viewState;
}
// #endregion

// #region COMPONENT
interface Props {
  setDrawerOpenState: (open: boolean) => void;
  setDisplayBoundaries: (boundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>) => void;
  setPoints: (points: Record<string, FeatureCollection<Point>>) => void;
  setLoading: (loading: boolean) => void;
  drawerOpen: boolean;
}

const isBetaPortal = window.location.hostname.toLowerCase().includes('portalbeta');

export function JobsDashboardTable(props: Props) {
  const userContext = useContext(UserContext);

  const [inputDialogState, setInputDialogState] = useState<ViewSaveDialogProps>({
    open: false,
    title: '',
    content: '',
    submitText: '',
    submit: () => { },
    viewName: '',
  });
  // use react router search params to filter the data
  const [searchParams, setSearchParams] = useSearchParams();

  // #region STATE
  const updateSearchStateParams = () => {
    const urlParams = dashboardStateToURLParams({
      sorting,
      grouping,
      columnOrder,
      columnVisibility,
      columnFilterFunctions: columnFilterFns,
      columnFilterValues: columnFilters
    });
    urlParams.sort()
    setSearchParams(urlParams);
  }

  const dashboardState = urlParamsToDashboardState(searchParams);
  const [sorting, setSorting] = useState<MRT_SortingState>(dashboardState.sorting ?? DEFAULT_COLUMN_SORT);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(dashboardState.columnVisibility ?? DEFAULT_VIS_STATE);
  const [grouping, setGrouping] = useState<MRT_GroupingState>(dashboardState.grouping ?? DEFAULT_GROUPING);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(dashboardState.columnOrder ?? DEFAULT_COLUMN_ORDER);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(DEFAULT_FILTERS);
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(DEFAULT_FILTER_FNS);

  const [retrievingJobs, setRetrievingJobs] = useState(false);
  const [updatingBoundaries, setUpdatingBoundaries] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<ATRecord<Companies>[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<ATRecord<Companies> | null>(null);
  const [views, setViews] = useState<ViewCollection>(DEFAULT_VIEWS);
  const [selectedViewDirty, setSelectedViewDirty] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<ViewDefinition | null>(null);
  const [branches, setBranches] = useState<ATRecord<Branches>[]>([]);

  const [jobs, setJobs] = useState<ATRecord<Jobs>[]>([]);
  const [userRecord, setUserRecord] = useState<ATRecord<Contacts>>();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectionDisplayedOnMap, setSelectionDisplayedOnMap] = useState<MRT_RowSelectionState>({});

  const [markJobsReadyVisible, setMarkJobsReadyVisible] = useState(false);

  const [mobileMenuElement, setMobileMenuElement] = React.useState<null | HTMLElement>(null);
  const [actionMenuElement, setActionMenuElement] = React.useState<null | HTMLElement>(null);
  const [actionMenuRow, setActionMenuRow] = React.useState<MRT_Row<ATRecord<Jobs>> | null>(null);

  const hasSelections = Object.keys(rowSelection).length > 0;
  const hasGrouping = grouping.length > 0;
  const tableHeight = hasSelections && hasGrouping ? `calc(100vh - 240px)` : `calc(100vh - 190px)`;

  const selectedJobs = Object.keys(rowSelection).map((key) => {
    const index = parseInt(key);
    if (!isNaN(index) && rowSelection[key]) {
      return jobs[index];
    }
  }) as ATRecord<Jobs>[];

  const unreadySelectedJobs = selectedJobs.filter(job => !getField(job, N_FieldReadyDate.airtable));

  useEffect(() => {
    // TODO Might not be necessary, might be ROGO user only setting
    const paramsCopy = new URLSearchParams(searchParams.toString());
    paramsCopy.sort();

    const selectedParams = new URLSearchParams(selectedView?.url ?? '');
    selectedParams.sort();

    setSelectedViewDirty(selectedView === null || selectedParams.toString() !== paramsCopy.toString());

    updateSearchStateParams();
  }, [selectedView, grouping, sorting, columnOrder, columnVisibility, columnFilterFns, columnFilters]);
  // #endregion

  // #region DROPDOWN LISTS
  const [jobStatusList, setJobStatusList] = useState<string[]>([]);
  const [testPackageList, setTestPackageList] = useState<string[]>([]);
  const [submitterList, setSubmitterList] = useState<string[]>([]);
  const [seasonList, setSeasonList] = useState<string[]>([]);
  const [branchList, setBranchList] = useState<string[]>([]);
  function setMultiSelectOptions(jobs: ATRecord<Jobs>[], branches: ATRecord<Branches>[] = []) {
    setSelectList(jobs, N_JobStatus.airtable, setJobStatusList);
    setSelectList(jobs, N_TestPackages.airtable, setTestPackageList);
    setSelectList(jobs, N_Submitter.airtable, setSubmitterList);
    setSelectList(jobs, N_Season.airtable, setSeasonList);
    setSelectList(jobs, N_Branch.airtable, setBranchList, branches);
  }

  function setSelectList(jobs: ATRecord<Jobs>[], fieldName: string, setList: (list: any[]) => void, branches: ATRecord<Branches>[] = []) {
    var all = jobs.map(job => getField_(job, fieldName, branches) as string).filter((value, index, self) => self.indexOf(value) === index).flat();
    var distinct = [...new Set(all)].filter(value => value !== undefined).sort();
    setList(distinct);

    function getField_(job: ATRecord<Jobs>, fieldName: string, branches: ATRecord<Branches>[] = []) {
      if (fieldName === N_Branch.airtable) {
        return getBranchField(job, true, branches);
      } else {
        return getField(job, fieldName);
      }
    }
  }

  function getBranchField(job: ATRecord<Jobs>, filter: boolean = false, b: ATRecord<Branches>[] = []) {
    const branchIds = getField(job, N_Branch.airtable) as string[];
    if (!branchIds) { 
      return ""; 
    }
    if (!branchIds.length) { 
      return ""; 
    }

    const branchId = branchIds[0];
    var branch = branches.find(branch => branch.id === branchId);
    if (!branch) { 
      branch = b.find(branch => branch.id === branchId);
    }
    if (!branch) { 
      return "";
    }

    return getField(branch, "Branch Name", branchId);
  }
  // #endregion
  
  // #region FUNCTIONS
  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function getData() {
      try {
        // setTableIsLoading(true);
        if (userContext == undefined){
          Sentry.captureMessage("userContext is undefined: JobsDashboardTable.tsx", "info");
          return;
        }
        const [userRecord, companyRecords, deals, importOptions, usersCompany] = await getUsersData(userContext?.id);
        setUserRecord(userRecord);
        companyRecords.sort((companyA, companyB) => getField(companyA, "Name", "").localeCompare(getField(companyB, "Name", "")));
        setCompanyOptions(companyRecords);
        if (companyRecords.length === 1) {
          await onCompanyChanged(companyRecords[0]);
        } else if (companyRecords.length > 1) {
          const urlCompanyId = searchParams.get('company');
          if (urlCompanyId) {
            const company = companyRecords.find(company => company.id === urlCompanyId);
            if (company) {
              await onCompanyChanged(company);
            }
          }
        }
      } catch (error) {
        toast.error('Failed to load data');
        Sentry.captureException(error, { mechanism: { handled: true } });
      }
    };

    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!jobs.length) {
      getData();
    }
  }, [userContext]);

  async function onCompanyChanged(company: ATRecord<Companies> | null) {
    setRetrievingJobs(true);
    try {
      setSelectedCompany(company);
      if (companyOptions.length > 1) {
        // updateSearchParams('company', value?.id ?? '');
        updateSearchStateParams();
      }
      setRowSelection({});
      let jobs: ATRecord<Jobs>[] = [];

      // remove all company views from the views state
      // const newViews: ViewCollection = [...views.filter(view => view.group !== 'Company Views')];

      const userPortalSettingsString = getField(userRecord, "Portal Settings") as string;
      const userPortalSettings: UserSettings = userPortalSettingsString ? JSON.parse(userPortalSettingsString) : {};

      const newViews: ViewCollection = [
        ...userPortalSettings.views ?? []
      ];

      let branches: ATRecord<Branches>[] = [];
      if (company) {
        jobs = await API.Jobs.get.forCurrentUserByCompany(company.id);

        branches = await getBranches(company);
        setBranches(branches);

        // get company views from 'Portal Views' property on company record
        const portalViews = getField(company, "Portal Views") as string;
        if (portalViews) {
          const companyViews = JSON.parse(portalViews) as ViewDefinition[];
          for (const view of companyViews) {
            newViews.push({ ...view });
          }
        }
      }

      newViews.push(...DEFAULT_VIEWS);

      setViews(newViews);

      // if (!searchParams.toString()) {
      // TODO we should actually get the last visited view, or the customer default view here
      const defaultView = views.filter(view => view.name === DEFAULT_VIEW_NAME)[0];
      const urlParams = new URLSearchParams(defaultView.url);
      urlParams.sort();
      const dashboardState = urlParamsToDashboardState(urlParams);
      let grouping = dashboardState.grouping;
      if (branches.length <= 1) {
        grouping = grouping.filter(group => group !== 'branch');
      }
      setSorting(dashboardState.sorting);
      setGrouping(grouping);
      setColumnOrder(dashboardState.columnOrder);
      setColumnVisibility(dashboardState.columnVisibility);
      setSelectedView(defaultView);
      setSearchParams(urlParams);
      // }

      setMultiSelectOptions(jobs, branches);
      setJobs(jobs);
    } catch {
      // handle error
    } finally {
      setRetrievingJobs(false);
      setMobileMenuElement(null);
    }
  }

  async function onSavedViewChanged(event: any, value: any) {
    event.stopPropagation();
    if (!value) {
      setSelectedView(value);
      return;
    }

    try {
      const urlParams = new URLSearchParams(value.url);
      urlParams.sort();
      const dashboardState = urlParamsToDashboardState(urlParams);
      setSorting(dashboardState.sorting);
      let grouping = dashboardState.grouping;
      // This is special logic to filter out the branch grouping if there is only one branch
      if (branches.length <= 1) {
        grouping = grouping.filter(group => group !== 'branch');
      }
      setGrouping(grouping);
      setColumnOrder(dashboardState.columnOrder);
      setColumnVisibility(dashboardState.columnVisibility);
      setColumnFilters(dashboardState.columnFilterValues);
      setColumnFilterFns(dashboardState.columnFilterFunctions);

      setSearchParams(urlParams);
      setSelectedView(value);
    } catch (error) {
      console.error(error);
    } finally {
      setMobileMenuElement(null);
    }
  };

  async function refreshJobs() {
    try {
      if (!selectedCompany) {
        return;
      }
      setRetrievingJobs(true);
      const jobs = await API.Jobs.get.forCurrentUserByCompany(selectedCompany.id);
      setMultiSelectOptions(jobs);
      setJobs(jobs);
    } catch (error) {
      toast.error('Failed to refresh jobs');
      Sentry.captureException(error, { mechanism: { handled: true } });
    } finally {
      setRetrievingJobs(false);
      setMobileMenuElement(null);
    }
  }

  async function updateBoundaries() {
    try {
      // setUpdatingBoundaries(true);

      const selectedJobs = Object.keys(rowSelection).map((key) => {
        const index = parseInt(key);
        if (!isNaN(index) && rowSelection[key]) {
          return jobs[index];
        }
      });

      // open the drawer if rows are selected
      props.setDrawerOpenState(Object.keys(rowSelection).length > 0);

      const displayedBoundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};
      const displayedPoints: Record<string, FeatureCollection<Point>> = {};
      await Promise.all(selectedJobs.map(async job => {
        if (!job) {
          return;
        }

        const [boundary, points] = await Promise.all([
          getBoundaryForJob(job),
          getPointsForJob(job)
        ]);
        if (boundary) {
          displayedBoundaries[job.id] = boundary;
        }

        if (points) {
          // @ts-ignore
          // TODO disabling for now. This will instead be turned on/selected on a per job basis. 
          displayedPoints[job.id] = points;
        }

      }));

      props.setDisplayBoundaries(displayedBoundaries);
      props.setPoints(displayedPoints);
      setSelectionDisplayedOnMap(rowSelection);
      return [Object.keys(displayedBoundaries).length, Object.keys(displayedPoints).length] as const;
    } catch (error) {
      console.error(error);
      return [0, 0] as const;
    } finally {
      // setUpdatingBoundaries(false);
    }
  }

  function clickButton(id: string): React.MouseEventHandler<HTMLLIElement> | undefined {
    return async () => { setMobileMenuElement(null); document.getElementById(id)?.click(); };
  }

  function isAdmin() {
    const email = getField(userRecord, "Email") as string;
    const isAdmin = email == "daniel.b@rogoag.com";
    return isAdmin;
  }

  async function showOnMap() {
    const loadingBoundariesToast = toast.loading('Updating boundaries...');
    const [boundaryCount, pointCount] = await updateBoundaries();
    toast.dismiss(loadingBoundariesToast);
    toast.info(`Displaying ${boundaryCount} boundaries and ${pointCount} point groups`);
    setMobileMenuElement(null);
  }
  
  function getMarkReadyText(unreadySelectedJobs: ATRecord<Jobs>[]): React.ReactNode {
    switch (unreadySelectedJobs.length) {
      case 0:
        return 'Mark Jobs Ready';
      case 1:
        return 'Mark 1 Job Ready';
      default:
        return `Mark ${unreadySelectedJobs.length} Jobs Ready`;
    }
  }
  
  function downloadFiles(attachments: Attachment[], columnName: string, rowName: string) {
    return async () => {
      const toastDownloading = toast.loading('Downloading files...');
  
      try {
        const blobs = await getBlobs();
        const zip = zipBlobs(blobs);
        downloadZip(zip);
      } catch (error) {
        console.error(error);
        toast.error('Failed to download files');
        toast.dismiss(toastDownloading);
      } finally {
        toast.dismiss(toastDownloading);
      }
  
      function getFileName() {
        var fileName = "";
  
        if (attachments.length === 1) {
          fileName = attachments[0].filename;
        } else if (attachments.length > 1) {
          fileName = rowName ? `${rowName} - ${columnName}.zip` : 'files.zip';
        } else {
          fileName = 'files.zip';
        }
  
        return fileName;
      }
  
      function zipBlobs(blobs: (Blob | null)[]) {
        const zip = new JSZip();
        const validBlobs = blobs.filter(blob => !!blob);
        validBlobs.forEach((blob, i) => {
          zip.file(attachments[i].filename, blob);
        });
        return zip;
      }
  
      async function getBlobs() {
        return await Promise.all(attachments.map(async (attachment) => {
          try {
            const response = await fetch(attachment.url);
            const blob = await response.blob();
            return blob;
          } catch (error) {
            console.error(error);
            return null;
          }
        }));
      }
  
      function downloadZip(zip: JSZip) {
        var fileName = getFileName();
  
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const url = URL.createObjectURL(content);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}`;
          a.click();
          URL.revokeObjectURL(url);
          toast.success(`Downloaded ${fileName}!`);
        });
      }
    }
  }

  // PROOF OF CONCEPT
  // this function is pretty rudementary, and will need improvement before it can go live.
  // also, it doesn't yet work properly with `onColumnFilterFnsChange: setColumnFilterFns,`
  const booleanFilterFn: MRT_FilterFn<any> = (row, columnId, filterValue) => {
    const columnValue = row.getValue<string>(columnId).toLowerCase();
    const queryElements = filterValue.match(/\w+/g);
    const terms = queryElements?.filter((element: string) => element !== "AND" && element !== "OR").map((element: string) => element.toLowerCase());
    const operators = filterValue.match(/(AND|OR)/g); // Extract operators
    
    if (!terms || terms.length === 0) {
      return true; // No filter applied
    }
    
    const evaluateAnds = (terms: string[], columnValue: string) => {
      return terms.reduce((acc, term) => acc && columnValue.includes(term), true);
    };

    let result = false;
    let currentTerms: string[] = [];
    let currentOperator = "OR";

    for (let i = 0; i < terms.length; i++) {
      currentTerms.push(terms[i]);
      const nextOperator = operators?.[i] || "OR";

      if (nextOperator === "OR" || i === terms.length - 1) {
      const andResult = evaluateAnds(currentTerms, columnValue);
      if (currentOperator === "AND") {
        result = result && andResult;
      } else {
        result = result || andResult;
      }
      currentTerms = [];
      currentOperator = nextOperator;
      }
    }
  
    return result || false;
  };

  function hasValue(record: ATRecord<Jobs>, fieldName: string) {
    return !!(getField(record, fieldName) || false);
  }

  function getShowMapText(): React.ReactNode {
    return selectionDisplayedOnMap !== rowSelection && props.drawerOpen ? "Update Map" : "Show on Map";
  }

  function deleteView() {
    if (selectedView) {
      const newViews: ViewCollection = [...views];
      const index = newViews.indexOf(selectedView);
      newViews.splice(index, 1);
      setViews(newViews);
      setSelectedView(null);

      updateViews(selectedView, newViews);
    }
    setMobileMenuElement(null);
  }

  async function copyLinkToClipboard() {
    try {
      // if this fails, it will throw
      await navigator.clipboard.writeText(window.location.href);

      // otherwise if we got here, then it should have worked
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link to clipboard');
    } finally {
      setMobileMenuElement(null);
    }
  }

  async function saveCurrentView() {
    let newName = selectedView?.name;
    // TODO prompt for name
    const result = await new Promise<[string, ViewGroup] | undefined>((resolve) => {
      setInputDialogState({
        open: true,
        title: 'Save current view',
        content: 'Please enter a name for this view',
        submitText: 'Save',
        submit: (result) => {
          resolve(result);
          setInputDialogState({ ...inputDialogState, open: false });
        },
        viewName: selectedView?.name ?? '',
      });
    });

    if (!result) {
      setMobileMenuElement(null);
      return;
    }

    const [name, type] = result;

    const newViews: ViewCollection = [
      ...views,
    ];

    const newView: ViewDefinition = {
      name,
      group: type,
      url: searchParams.toString(),
      lastUpdated: new Date().toISOString()
    };
    if (selectedView && name === selectedView.name && type === selectedView.group) {
      newViews[newViews.indexOf(selectedView)] = newView;
    } else {
      // look for a view that has the same group type as our new value
      for (const view of newViews) {
        if (view.group === newView.group || view.group === ViewGroup.Default) {
          // insert the view right before the first view with the same group
          newViews.splice(newViews.indexOf(view), 0, newView);
          break;
          // because we always sort the Default group to the 
          // end of the list, we can break here 
        }
      }
    }
    setViews(newViews);
    setSelectedView(newView);

    updateViews(newView, newViews);
    setMobileMenuElement(null);
  }

  function updateViews(newView: ViewDefinition, newViews: ViewCollection) {
    if (newView.group === ViewGroup.User) {
      const userViews = newViews.filter(view => view.group === ViewGroup.User);
      if (userContext) {
        API.Users.update.one(userContext.id, { "Portal Settings": JSON.stringify({ views: userViews })});
      }
    } else if (newView.group === ViewGroup.Company) {
      const companyViews = newViews.filter(view => view.group === ViewGroup.Company);
      API.Companies.update.one(selectedCompany!.id, { "Portal Views": JSON.stringify(companyViews) });
    }
  }

  // #endregion

  // #region MENUS / TOOLBAR
  function toolbar(table: any) {
    const noJobs = jobs.length === 0;
    const noViewSelected = !selectedView;
    const viewIsReadonly = !!selectedView && selectedView.group === ViewGroup.Default;

    // we will actually always allow the save button when we have a default view selected
    // that way we will allow the user to "copy" the view by saving it under their own name
    const canSaveViewState = !viewIsReadonly && !selectedViewDirty;
    const cantSaveViewState = !canSaveViewState;
    const saveViewDisabled = noJobs && cantSaveViewState;
    const deleteViewDisabled = viewIsReadonly || noJobs || noViewSelected;

    return (
      <Box
        sx={(theme) => ({
          backgroundColor: lighten(theme.palette.background.default, 0.05),
          display: 'flex',
          pl: '8px',
        })}
      >
        <div id="MRT-sub-components" className='horizontal-button-group'>
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </div>

        <div id="right-controls">
          {/* only show controls if NOT expanded */}
          {!props.drawerOpen && (<>
            <div id="company-view-dropdowns" className="hide-on-mobile-screen">
              {companyOptions.length > 1 &&
                <AirtableRecordSelectionAutocomplete
                  key="Name"
                  loading={false}
                  options={companyOptions}
                  selectedOption={selectedCompany}
                  onChange={onCompanyChanged}
                  className="dashboard-dropdown"
                  label="Company"
                  tooltip="Select a company to view their jobs and views. This is only for ROGO employees"
                  placement='right'
                  size="small" />}
              <Autocomplete
                className="dashboard-dropdown"
                options={views}
                value={selectedView}
                disabled={jobs.length === 0}
                groupBy={(option) => option.group === 'Default' ? 'System Views' : option.group.toString()}
                getOptionKey={(option) => option.group + option.name}
                getOptionLabel={(option) => option.name}
                onChange={onSavedViewChanged}
                renderInput={(params) => <TextField {...params} label="Saved View" variant="outlined" size="small" />} />
            </div>
            <div id="toolbar-menu" className='horizontal-button-group show-on-small-screen'>
              <div>
                <IconButton
                  onClick={(event) => { setMobileMenuElement(event.currentTarget); } }
                  className='icon-button'
                  id="toolbar-menu-button">
                  <MoreHoriz />
                </IconButton>
              </div>
            </div>
            <div id="view-button" className="horizontal-button-group hide-on-small-screen">
              <Tooltip title="Refresh jobs">
                <IconButton
                  id="refresh-jobs-button"
                  onClick={refreshJobs}
                  disabled={jobs.length === 0}
                  className='icon-button'>
                  <RefreshOutlined fontSize='medium' style={{ 'color': jobs.length === 0 ? 'grey' : 'blue' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save current view">
                <IconButton
                  id="save-view-button"
                  onClick={saveCurrentView}
                  className='icon-button'
                  disabled={saveViewDisabled}>
                  <Save fontSize='medium' style={{ 'color': saveViewDisabled ? 'grey' : 'green' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy link to clipboard">
                <IconButton
                  id="copy-link-button"
                  onClick={copyLinkToClipboard}
                  className='icon-button'
                  disabled={noJobs}>
                  <ContentCopy fontSize='medium' style={{ 'color': noJobs || noViewSelected ? 'grey' : 'blue' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete view">
                <IconButton
                  id="delete-view-button"
                  onClick={deleteView}
                  className='icon-button'
                  disabled={deleteViewDisabled}>
                  <Delete fontSize='medium' style={{ 'color': deleteViewDisabled ? 'grey' : 'red' }} />
                </IconButton>
              </Tooltip>
            </div>
          </>)}
          <div id="big-blue-buttons" className="horizontal-button-group">
            <div id="mark-ready-buttons" className="multi-screen-size-button-group hide-on-small-screen">
              <Button
                id="mark-ready-button"
                className='text-button big-screen-button'
                onClick={() => { setMarkJobsReadyVisible(true); } }
                color="primary"
                variant="contained">
                {getMarkReadyText(unreadySelectedJobs)}
              </Button>
              <Tooltip title={getMarkReadyText(unreadySelectedJobs)}>
                <IconButton className='icon-button medium-screen-button' onClick={() => { setMarkJobsReadyVisible(true); } }>
                  <Check fontSize='medium' />
                </IconButton>
              </Tooltip>
            </div>
            <div id="show-on-map-buttons" className='multi-screen-size-button-group'>
              <Button
                id="show-on-map-button"
                onClick={showOnMap}
                className='text-button big-screen-button'
                color="primary"
                variant="contained"
                disabled={Object.keys(selectionDisplayedOnMap).length === Object.keys(rowSelection).length &&
                  Object.keys(rowSelection).every((key) => !!selectionDisplayedOnMap[key])}>
                {getShowMapText()}
              </Button>
              <Tooltip title={getShowMapText()}>
                <IconButton className='icon-button medium-screen-button' onClick={showOnMap}>
                  <Map fontSize='medium' />
                </IconButton>
              </Tooltip>
              {props.drawerOpen ? (<IconButton
                onClick={() => { props.setDrawerOpenState(!props.drawerOpen); } }
                className='icon-button'
                id="right-drawer-button"
                disabled={noJobs}>
                <KeyboardDoubleArrowRight fontSize='large' style={{ 'color': noJobs ? 'grey' : undefined }} />
              </IconButton>
              ) : <></>}
            </div>
          </div>
        </div>
      </Box>
    );
  }

  function downloadMenu() {
    var downloadMenuItems = [];

    downloadMenuItems.push(<MenuItem>
      <strong>Files for {getItemName()}</strong>
    </MenuItem>);

    downloadMenuItems.push(<MenuItem onClick={downloadFiles(getAllFiles(), "All Files", getItemName())}>
      <Download /> All Files
    </MenuItem>);

    FILE_COLUMNS.forEach(column => {
      addDownloadButton(column);
    });

    if (isAdmin() && getJobID() != "") {
      downloadMenuItems.push(<MenuItem onClick={copyJobID}>
        <strong>{getJobID()}</strong>
      </MenuItem>);
    }

    return <Menu
      anchorEl={actionMenuElement}
      open={Boolean(actionMenuElement)}
      onClose={() => setActionMenuElement(null)} >
      { downloadMenuItems }
    </Menu>;



    function copyJobID(){
      navigator.clipboard.writeText(getJobID()).then(() => {
        toast.success('Job ID copied to clipboard');
      }).catch(() => {
        toast.error('Failed to copy Job ID to clipboard');
      });
      return;
    }

    function getJobID() {
      var jobid = "";

      if (!actionMenuRow) {
        if (selectedJobs.length === 0) {
          jobid = "";
        } else {
          jobid = "";
        }
      } else if (actionMenuRow.getIsGrouped()) {
        jobid = ""
      } else {
        var job = actionMenuRow?.original as ATRecord<Jobs>;
        jobid = job.id;
      }

      return jobid;
    }

    function getAllFiles() {
      var allFiles: Attachment[] = [];

      FILE_COLUMNS.forEach(column => {
        var files = getFiles(column);
        allFiles.push(...files);
      });

      return allFiles;
    }

    function getItemName() {
      var itemName = "";

      if (!actionMenuRow) {
        if (selectedJobs.length === 0) {
          itemName = "All Jobs";
        } else {
          itemName = "Selected Jobs";
        }
      } else if (actionMenuRow.getIsGrouped()) {
        itemName = actionMenuRow.groupingValue as string;
      } else {
        var job = actionMenuRow?.original as ATRecord<Jobs>;
        itemName = getField(job, N_Field.airtable) as string;
      }

      return itemName;
    }

    function addDownloadButton(fieldNames: FieldNames) {
      var rowName = getRowName();
      var files: Attachment[] = getFiles(fieldNames);

      if (files?.length > 0) {
        downloadMenuItems.push(downloadButton(fieldNames.columnLabel, files, rowName));
      } else {
        downloadMenuItems.push(<MenuItem disabled>
          <Download /> {fieldNames.columnLabel}
        </MenuItem>);
      }

      function getRowName() {
        var rowName = "";
        if (!actionMenuRow) {
          if (selectedJobs.length === 0) {
            rowName = "All Jobs";
          } else {
            rowName = "Selected Jobs";
          }
        } else if (actionMenuRow.getIsGrouped()) {
          rowName = actionMenuRow.groupingValue as string;
        } else {
          rowName = getField(actionMenuRow.original, N_Field.airtable) as string;
        }
        return rowName;
      }
    }

    function getFiles(fieldNames: FieldNames) {
      var files: Attachment[] = [];

      if (!actionMenuRow){ 
        // All files
        if (selectedJobs.length === 0) { 
          for (let i = 0; i < (jobs.length ?? 0); i++) {
            getFilesFromJob(jobs[i]);
          }
        // Selected files
        } else { 
          for (let i = 0; i < (selectedJobs.length ?? 0); i++) {
            getFilesFromJob(selectedJobs[i]);
          }
        }
      // Grouped files
      } else if (actionMenuRow.getIsGrouped()) { 
        const getFilesRecursive = (subRows: MRT_Row<ATRecord<Jobs>>[]) => {
          subRows.forEach(subRow => {
            if (subRow.subRows?.length) {
              getFilesRecursive(subRow.subRows);
            } else {
              getFilesFromJob(subRow.original);
            }
          });
        };

        getFilesRecursive(actionMenuRow?.subRows ?? []);
      // Single file
      } else { 
        getFilesFromJob(actionMenuRow?.original);
      }

      return files;

      function getFilesFromJob(job: ATRecord<Jobs>) {
        var file: Attachment[] = getField(job, fieldNames.airtable) as Attachment[];

        if (file) {
          for (let i = 0; i < file.length; i++) {
            files.push(file[i]);
          }
        }
      }
    }

    function downloadButton(columnName: string, files: Attachment[], groupName: string) {
      return <MenuItem onClick={downloadFiles(files, columnName, groupName)}>
        <Download /> {columnName}
      </MenuItem>;
    }
  };

  function mobileMenu() {
    return (
    <Menu
      anchorEl={mobileMenuElement}
      open={Boolean(mobileMenuElement)}
      onClose={() => setMobileMenuElement(null)}>
      {companyOptions.length > 1 &&
        <AirtableRecordSelectionAutocomplete
          id="company-dropdown"
          key="Name"
          loading={false}
          options={companyOptions}
          selectedOption={selectedCompany}
          onChange={onCompanyChanged}
          className="dashboard-dropdown show-on-mobile-screen"
          label="Company"
          tooltip="Select a company to view their jobs and views. This is only for ROGO employees"
          placement='right'
          size="small" />}
      <Autocomplete
        id="view-dropdown"
        className="dashboard-dropdown show-on-mobile-screen"
        options={views}
        value={selectedView}
        disabled={jobs.length === 0}
        groupBy={(option) => option.group === 'Default' ? 'System Views' : option.group.toString()}
        getOptionKey={(option) => option.group + option.name}
        getOptionLabel={(option) => option.name}
        onChange={onSavedViewChanged}
        renderInput={(params) => <TextField {...params} label="Saved View" variant="outlined" size="small" />} />
      <MenuItem onClick={refreshJobs}>
        <RefreshOutlined /><div className="right-menu-text">Refresh Jobs</div>
      </MenuItem>
      <MenuItem onClick={saveCurrentView}>
        <Save /><div className="right-menu-text">Save Current View</div>
      </MenuItem>
      <MenuItem onClick={copyLinkToClipboard}>
        <ContentCopy /><div className="right-menu-text">Copy Link to Clipboard</div>
      </MenuItem>
      <MenuItem onClick={deleteView}>
        <Delete /><div className="right-menu-text">Delete View</div>
      </MenuItem>
      <MenuItem onClick={() => { setMarkJobsReadyVisible(true); } }>
        <Check /><div className="right-menu-text">Mark Jobs Ready</div>
      </MenuItem>
      <MenuItem onClick={showOnMap}>
        <Map /><div className="right-menu-text">Show on Map</div>
      </MenuItem>
    </Menu>);
  }
  // #endregion

  // #region TABLE
  const columns = useMemo<MRT_ColumnDef<ATRecord<Jobs>>[]>(
    () =>
      [
        textColumn(N_Grower.columnLabel, { size: 200 }),
        textColumn(N_Field.columnLabel, { accessorKeyOrFn: N_Field.airtable }),
        textColumn(N_Farm.columnLabel, { accessorKeyOrFn: N_Farm.airtable }),
        multiSelectColumn(N_JobStatus.columnLabel, { accessorKeyOrFn: N_JobStatus.airtable, filterSelectOptions: jobStatusList }),
        numericColumn(N_BoundaryAcres.columnLabel, { aggregationFn: 'sum', slider: false }),
        multiSelectColumn(N_Branch.columnLabel, { accessorKeyOrFn: getBranchField, filterSelectOptions: branchList }),
        dateColumn(N_FieldReadyDate.columnLabel, { isRangeFilter: true }),
        dateColumn(N_SampleDate.columnLabel, { isRangeFilter: true }),
        dateColumn(N_CreationDate.columnLabel, { accessorKeyOrFn: N_CreationDate.airtable, isRangeFilter: true }),
        dateColumn(N_DropShipDate.columnLabel, { defaultText: 'Not shipped', isRangeFilter: true }),
        multiSelectColumn(N_TestPackages.columnLabel, { accessorKeyOrFn: N_TestPackages.airtable, filterSelectOptions: testPackageList }),
        checkboxColumn(N_BoundaryChange.columnLabel, { accessorKeyOrFn: (record) => { return hasValue(record, N_BoundaryChange.airtable); } }),
        checkboxColumn(N_BoundaryApproved.columnLabel, { accessorKeyOrFn: (record) => { return hasValue(record, N_BoundaryApproved.airtable); } }),
        checkboxColumn(N_PointsChange.columnLabel, { accessorKeyOrFn: (record) => { return hasValue(record, N_PointsChange.airtable); } }),
        multiSelectColumn(N_Submitter.columnLabel, { filterSelectOptions: submitterList }), // Lab Results
        multiSelectColumn(N_Season.columnLabel, { filterSelectOptions: seasonList }),
        checkboxColumn(N_ApprovedBilling.columnLabel, { accessorKeyOrFn: N_ApprovedBilling.airtable }),
        checkboxMultiselectColumn('Job Flags', 'Map Issue'),

        // fileColumn(N_Boundary.columnLabel, { accessorKeyOrFn: N_Boundary.airtable }),
        // fileColumn(N_Points.columnLabel, { accessorKeyOrFn: N_Points.airtable }),
        // fileColumn(N_SampleZones.columnLabel, { accessorKeyOrFn: N_SampleZones.airtable }),
        // fileColumn(N_ExecutedPoints.columnLabel, { accessorKeyOrFn: N_ExecutedPoints.airtable }),
        // fileColumn(N_ExecutedBoundary.columnLabel, { accessorKeyOrFn: N_ExecutedBoundary.airtable }),
        // fileColumn(N_ExecutedPointsBoundary.columnLabel, { accessorKeyOrFn: N_ExecutedPointsBoundary.airtable }),

        dateColumn(N_LabSentResults.columnLabel, { accessorKeyOrFn: N_LabSentResults.airtable, defaultText: 'Not Sent', isRangeFilter: true }),
        // fileColumn(N_LabResults.columnLabel),
        // fileColumn(N_SamplingCSV.columnLabel, { accessorKeyOrFn: N_SamplingCSV.airtable }),
        
        textColumn(N_EventID.columnLabel, { accessorKeyOrFn: N_EventID.airtable }),
        textColumn(N_LabSubmittalID.columnLabel, { accessorKeyOrFn: N_LabSubmittalID.airtable }),
        numericColumn(N_Depth.columnLabel, { aggregationFn: 'sum', slider: false, sliderStepSize: 1 }),
        textColumn(N_SampleChanges.columnLabel, { accessorKeyOrFn: N_SampleChanges.airtable }),
      ],
    [branches],
  );

  const table = useMaterialReactTable({
    columns,
    data: jobs, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: false,
    enableColumnFilters: true,
    enableColumnOrdering: true,
    enableColumnDragging: true,
    enableColumnPinning: true,
    enableGrouping: true,
    enableHiding: true,
    enableFacetedValues: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTableFooter: true,
    enableMultiSort: true,
    enableGlobalFilterModes: true,
    enableGlobalFilterRankedResults: true,
    enableRowSelection: true,
    enableSubRowSelection: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    columnFilterDisplayMode: 'subheader',
    muiTableContainerProps: { sx: { height: tableHeight }, },
    muiTablePaperProps: { sx: { height: '100vh - 30px)' } },
    maxMultiSortColCount: 3,
    initialState: {
      expanded: true,
      showGlobalFilter: true,
      density: 'compact',
      pagination: {
        pageSize: 20,
        pageIndex: 0
      },
      columnVisibility: {
        [N_Grower.columnName()]: false
      },
      columnPinning: {
        left: ['mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    filterFns: {
      'booleanFilter': booleanFilterFn,
    },
    selectAllMode: 'all',
    state: {
      // URL based state
      columnVisibility,
      grouping,
      sorting,
      columnOrder,
      columnFilters: columnFilters,
      columnFilterFns: columnFilterFns,
      rowSelection,
      isLoading: retrievingJobs || updatingBoundaries,
    },
    muiCircularProgressProps: {
      Component: <LoadingComponent />,
    },
    onGroupingChange: setGrouping,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    groupedColumnMode: false,
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 40,
        visibleInShowHideMenu: true,
      },
      'mrt-row-actions': {
        header: 'Files',
        size: 40,
        visibleInShowHideMenu: true,
        Header: () => {
          return [
            <Box className="download-button">
              <IconButton color="primary" onClick={(event) => { setActionMenuElement(event?.currentTarget); setActionMenuRow(null); }}>
                <Download />
              </IconButton>
              <Box className="hide-on-small-screen">Files</Box>
            </Box>
          ];
        },
        AggregatedCell: ({ row }) => {
          return (
            <Box className="download-button">
              <IconButton color="primary" onClick={(event) => { setActionMenuElement(event?.currentTarget); setActionMenuRow(row); }}>
                <Download />
              </IconButton>
              <Box className="hide-on-small-screen">Files</Box>
            </Box>
          );
        },
        Cell: ({ row }) => {
          return (
            <Box className="download-button">
              <IconButton color="primary" onClick={(event) => { setActionMenuElement(event?.currentTarget); setActionMenuRow(row); }}>
                <Download />
              </IconButton>
              <Box className="hide-on-small-screen">Files</Box>
            </Box>
          );
        },
      },
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        // @ts-ignore
        GroupedCell: ({ row, column }) => {
          const value = row.getValue(grouping[grouping.length - 1]);
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return value;
        },
        Cell: ({ row, column }) => {
          const groupingId = grouping[Math.min(row.depth, grouping.length - 1)];
          const value = row.getValue(groupingId);

          let label: string | unknown = value
          if (value instanceof Date) {
            label = value.toLocaleDateString();
          }
          return (
            <Stack direction="row" alignItems="center">
              <MRT_ExpandButton row={row} table={table} />
              <Box>{label?.toString() || "n/a"}</Box>
            </Stack>
          );
        },
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 200,
        visibleInShowHideMenu: true,
      },
    },
    paginationDisplayMode: 'default',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 15, 20, 30, 50],
      showRowsPerPage: !props.drawerOpen,
    },
    renderTopToolbar: ({ table }) => { return toolbar(table); },
  });
  
  return <>
    <MaterialReactTable table={table} />
    <ViewSaveDialog {...inputDialogState} />
    <MarkJobReadyPopover
      open={markJobsReadyVisible}
      jobs={jobs.filter(job => !getField(job, N_FieldReadyDate.airtable))}
      intialSelectedJobs={ markJobsReadyVisible ? unreadySelectedJobs : [] }
      setLoading={setRetrievingJobs}
      onSuccess={refreshJobs}
      setOpen={(open) => setMarkJobsReadyVisible(open)}
    />
    {downloadMenu()}
    {mobileMenu()}
  </>;
  // #endregion

};
// #endregion

