import { ATRecord, Companies, getField, Jobs } from "@rogoag/airtable";
import { useEffect, useState, useContext } from "react";
import { MarkJobReadyForm } from "./MarkJobReadyForm";
import { createJobReady, getUsersData } from "../api/airtable_ops";

import { UserContext } from '../hooks/UserContext';
import AirtableRecordSelectionAutocomplete from "./AirtableRecordSelectionAutocomplete";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingComponent } from "./LoadingComponent";
import { sleep } from "../utils";
import * as Sentry from "@sentry/react";
import * as API from '../API';

interface Props {
    setLoading: (loading: boolean) => void;
}

export function MarkJobReadyPage(props: Props) {
    const [jobs, setJobs] = useState<ATRecord<Jobs>[]>([]);
    const [companyOptions, setCompanyOptions] = useState<ATRecord<Companies>[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<ATRecord<Companies> | null>(null);
    const [loading, setLoading] = useState(false);

    const userContext = useContext(UserContext);

    async function onCompanyChanged(company: ATRecord<Companies> | null) {
        try {
            setLoading(true);
            setJobs([]);
            setSelectedCompany(company);
            let jobs: ATRecord<Jobs>[] = [];
            if (company) {
                jobs = await API.Jobs.get.forCurrentUserByCompany(company.id);
                const unreadyJobs = jobs.filter(job => !getField(job, "Field Ready Date"))
                console.log(unreadyJobs);
                setJobs(unreadyJobs);
            }
        } catch {
            // handle error
        } finally {
            setLoading(false);
        }
    }

    async function markJobsReady(data: any) {
        setLoading(true);
        await createJobReady(data);
        await sleep(1000);
        await onCompanyChanged(selectedCompany);
        toast.success(`Marked ${data.selectedJobs.length} jobs as ready!`);
    }

    useEffect(() => {
        // React advises to declare the async function directly inside useEffect
        async function getData() {
            try {
                if (userContext == undefined){
                  Sentry.captureMessage("userContext is undefined: MarkJobReadyPage.tsx", "info");
                  return;
                }
                const [userRecord, companyRecords, deals, importOptions, userCompany] = await getUsersData(userContext?.id);
                companyRecords.sort((companyA, companyB) => getField(companyA, "Name").localeCompare(getField(companyB, "Name")));
                setCompanyOptions(companyRecords);
                if (companyRecords.length === 1) {
                    const companyRecord = companyRecords[0];
                    await onCompanyChanged(companyRecord);
                }
            } catch (error) {
                toast.error("Error loading data");
                Sentry.captureException(error, { mechanism: { handled: true } });
            }
        };

        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (!jobs.length) {
            getData();
        }
    }, [userContext]);

    return (<>
        <br />
        <div id="choose-your-company">
            {companyOptions.length > 1 &&
                <AirtableRecordSelectionAutocomplete
                    key="Name"
                    loading={false}
                    options={companyOptions}
                    selectedOption={selectedCompany}
                    onChange={onCompanyChanged}
                    label="Choose your company"
                    style={{ margin: 10 }}
                    tooltip="Select a company to submit jobs for. This is only for ROGO employees"
                />
            }
            {loading ?
                <Box display="flex" justifyContent="center" alignItems="center">
                    <LoadingComponent />
                </Box> :
                <MarkJobReadyForm
                    intialSelectedJobs={[]}
                    jobs={jobs}
                    onSubmit={markJobsReady}
                    onCancel={() => { }} />
            }
        </div >
    </>);
}