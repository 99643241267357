import { RogoPortalUser } from '../hooks/UserContext';
import { Typography, Card, CardContent, CardActionArea, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface LandingPageProps {
  setLoggedIn: (user: RogoPortalUser) => void;
}

export default function LandingPage(props: LandingPageProps) {
    const navigate = useNavigate();

    function linkCard(url: string, title: string) {
        return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Card className='home-card'>
                    <CardActionArea onClick={() => { navigate(url); } }>
                        <CardContent>
                            <Typography variant="h6">{title}</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        );
    }

    return (<>
        <Grid container spacing={2} className='home-grid'>
            {linkCard('/jobs/dashboard', 'Jobs Dashboard')}
            {linkCard('/jobs/submit', 'Submit Jobs')}
            {linkCard('/jobs/ready', 'Mark Jobs Ready')}
        </Grid>
    </>);
}
