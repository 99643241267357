// #region IMPORTS
import axios, { AxiosRequestConfig } from 'axios';
import { env } from './config';
import { FieldSet } from 'airtable';
import { inflate } from 'pako';
import * as utils from './utils';
import { getCurrentUser } from './api/airtable_ops';
import { 
    ATRecord, 
    Contacts as Contacts_AT, 
    Jobs as Jobs_AT,
    Deals as Deals_AT,
    Companies as Companies_AT,
    Growers as Growers_AT,
    getFieldId,
    getField,
} from '@rogoag/airtable';
import { 
    ContactsFieldIdMapping, 
    JobsFieldIdMapping, 
    DealsFieldIdMapping, 
    CompaniesFieldIdMapping, 
    GrowersFieldIdMapping 
} from '../node_modules/@rogoag/airtable/rogo.at';
// #endregion

// #region CONSTANTS
const TOKEN = localStorage.getItem('rogo_id_token');
const AXIOS_CONFIG: AxiosRequestConfig<any> = {
    headers: { Authorization: `Bearer ${TOKEN}` },
};
const AXIOS_CONFIG_COMPRESSED: AxiosRequestConfig<any> = {
    headers: { Authorization: `Bearer ${TOKEN}` },
    responseType: 'blob',
};
// #endregion

// #region HELPERS
async function handleError(error: Error) {
    const user = await getCurrentUser();
    utils.logErrorToSentry(user.username, error);
}

async function getCompressed(url: string) {
    const response = await axios.get(url, AXIOS_CONFIG_COMPRESSED);
    const decompressedData = inflate(await response.data.arrayBuffer(), { to: 'string' });
    var records = JSON.parse(decompressedData);
    return records;
}

interface UpdateRecordDict {
    id: string;
    fields: { [key: string]: any };
}
// #endregion

// #region CREATE BASE
class CreateAPI_Base<T extends FieldSet> {
    private router: string;
    protected baseUrl: string;

    constructor(router: string){
        this.router = router;
        this.baseUrl = `${env.apiURL}/${this.router}`;
    }

    async many(records: Partial<T>[]) {
        let url = this.baseUrl;

        try {
            const response = await axios.post(url, records, AXIOS_CONFIG);
            if (response.status != 200) {
                throw new Error(`Create failed for ${this.router}: ${response.statusText}`);
            }
        } catch (error: any) {
            handleError(error);
        }
    }

    async one(record: Partial<T>) {
        this.many([record]);
    }
}
// #endregion

// #region GET BASE
class GetAPI_Base<T extends FieldSet> {
    private router: string;
    protected baseUrl: string;

    constructor(router: string){
        this.router = router;
        this.baseUrl = `${env.apiURL}/${this.router}`;
    }

    public async all(airtableFormula?: string, viewId?: string, isCompressed?: boolean): Promise<ATRecord<T>[]> {
        let queryParams: string[] = [];
        if (airtableFormula) queryParams.push(`airtable_formula=${encodeURIComponent(airtableFormula)}`);
        if (viewId) queryParams.push(`view_id=${encodeURIComponent(viewId)}`);
        let queryString = queryParams.length ? `?${queryParams.join('&')}` : "";

        let url = `${this.baseUrl}${queryString}`;

        try {
            if (isCompressed) {
                var records: ATRecord<T>[] = await getCompressed(url) as ATRecord<T>[];
                return records;
            } else {
                const response = await axios.get(url, AXIOS_CONFIG);
                var records = response.data as ATRecord<T>[];
                return records;
            }
        } catch (error: any) {
            handleError(error);
            return [];
        }
    }

    public async byFormula(airtableFormula: string, isCompressed?: boolean): Promise<ATRecord<T>[]> {
        return this.all(airtableFormula, undefined, isCompressed);
    }

    public async byViewId(viewId: string, isCompressed?: boolean): Promise<ATRecord<T>[]> {
        return this.all(undefined, viewId, isCompressed);
    }

    public async byIds(ids: string[], isCompressed?: boolean): Promise<ATRecord<T>[]> {
        const formula = `OR(${ids.map((id) => `RECORD_ID()='${id}'`).join(',')})`;
        return this.all(formula, undefined, isCompressed);
    }

    public async forCurrentUser(isCompressed: boolean = false): Promise<ATRecord<T>[]> {
        let url = `${this.baseUrl}/mine`;

        try {
            if (isCompressed) {
                var records: ATRecord<T>[] = await getCompressed(url);
                return records as ATRecord<T>[];
            } else {
                const response = await axios.get(url, AXIOS_CONFIG);
                var records = response.data as ATRecord<T>[];
                return records;
            }
        } catch (error: any) {
            handleError(error);
            return [];
        }
    }

    public async byId(id: string, isCompressed: boolean = false): Promise<ATRecord<T>> {
        let url = `${this.baseUrl}/${id}`;

        try {
            if (isCompressed) {
                var records = await getCompressed(url);
                return records[0] as ATRecord<T>;
            } else {
                const response = await axios.get(url, AXIOS_CONFIG);
                var record = response.data as ATRecord<T>;
                return record;
            }
        } catch (error: any) {
            handleError(error);
            return { id: "", table: "" } as ATRecord<T>;
        }
    }
}
// #endregion

// #region UPDATE BASE
class UpdateAPI_Base<T extends FieldSet> {
    private router: string;
    protected baseUrl: string;
    private fieldMapping: any;

    constructor(router: string, fieldMapping: any) {
        this.router = router;
        this.baseUrl = `${env.apiURL}/${this.router}`;
        this.fieldMapping = fieldMapping;
    }

    public async many(records: Partial<T>[]) {
        const url = this.baseUrl;

        try {
            const updates: UpdateRecordDict[] = this.convertToUpdates(records);
            const response = await axios.put(url, updates, AXIOS_CONFIG);
            if (response.status !== 200) {
                throw new Error(`Update failed for ${this.router}: ${response.statusText}`);
            }
        } catch (error: any) {
            handleError(error);
        }
    }

    public async one(id: string, records: Partial<T>) {
        const url = `${this.baseUrl}/${id}`;

        try {
            const updates: { [key: string]: any } = this.convertToUpdate(records);
            const response = await axios.put(url, updates, AXIOS_CONFIG);
            if (response.status !== 200) {
                throw new Error(`Update failed for ${this.router}: ${response.statusText}`);
            }
        } catch (error: any) {
            handleError(error);
        }
    }

    public async field(id: string, field: string, value: any) {
        const url = `${this.baseUrl}/${id}/${field}/${value}`;

        try {
            const response = await axios.put(url, AXIOS_CONFIG);
            if (response.status !== 200) {
                throw new Error(`Update failed for ${this.router}: ${response.statusText}`);
            }
        } catch (error: any) {
            handleError(error);
        }
    }

    private convertToUpdates(records: Partial<T>[]): UpdateRecordDict[] {
        var updates: UpdateRecordDict[] = [];
        for (let i = 0; i < records.length; i++) {
            var fields: { [key: string]: any; } = {};

            for (const field in this.fieldMapping) {
                if (records[i][field] !== undefined) {
                    fields[field] = records[i][field];
                }
            }

            var update: UpdateRecordDict = {
                id: records[i].id as string,
                fields: fields
            };
            updates.push(update);
        }
        return updates;
    }

    private convertToUpdate(record: Partial<T>): { [key: string]: any } {
        return this.convertToUpdates([record])[0].fields;
    }
}
// #endregion

// #region API BASE
class API_Base<T extends FieldSet> {
    private router: string;
    protected baseUrl: string;
    public create: CreateAPI_Base<T>;
    public get: GetAPI_Base<T>;
    public update: UpdateAPI_Base<T>;

    constructor(router: string, fieldMapping: any) {
        this.router = router;
        this.baseUrl = `${env.apiURL}/${this.router}`;
        this.create = new CreateAPI_Base<T>(router);
        this.get = new GetAPI_Base<T>(router);
        this.update = new UpdateAPI_Base<T>(router, fieldMapping);
    }
}
// #endregion

// #region CUSTOM ENDPOINTS
class GetAPI_Jobs extends GetAPI_Base<Jobs_AT> {
    constructor() { super("jobs"); }
    // We want Jobs to be compressed by default

    public override async all(airtableFormula?: string, viewId?: string) {
        return super.all(airtableFormula, viewId, true);
    }

    public override async byFormula(airtableFormula: string) {
        return super.byFormula(airtableFormula, true);
    }

    public override async byViewId(viewId: string) {
        return super.byViewId(viewId, true);
    }

    public override async byIds(ids: string[]) {
        return super.byIds(ids, true);
    }

    public override async forCurrentUser() {
        return super.forCurrentUser(true);
    }

    public override byId(id: string) {
        return super.byId(id, true);
    }

    public async forCurrentUserByCompany(companyId: string = "") {
        let queryString = companyId ? `?company_id=${companyId}` : "";
        let url = `${this.baseUrl}/mine${queryString}`;
    
        try {
            var records = await getCompressed(url) as ATRecord<Jobs_AT>[];
            return records;
        } catch (error: any) {
            handleError(error);
            return [];
        }
    }
}

class GetAPI_Deals extends GetAPI_Base<Deals_AT> {
    constructor() { super("deals"); }

    public async activeAndPrimary(dealIds: string[]) {
        const airtableFormula = `OR(${dealIds.map((id) => `AND(RECORD_ID()='${id}', {SAMPLING CONFIGURATION ONLY}=FALSE(), {Active}=TRUE())`).join(',')})`;
    
        return this.all(airtableFormula);
    }
}

class GetAPI_Growers extends GetAPI_Base<Growers_AT> {
    constructor() { super("growers"); }

    public async byCompany(company: ATRecord<Companies_AT>) {
        const airtableFormula = `{${getFieldId('Growers', 'Clients Unique')}}='${getField(company, 'Client Clean')}'`;
        
        return this.all(airtableFormula);
    }
}
// #endregion

// #region API INSTANCES
class UsersAPI extends API_Base<Contacts_AT> {
    constructor() { super("users", ContactsFieldIdMapping); }
}

class JobsAPI extends API_Base<Jobs_AT> {
    get: GetAPI_Jobs;

    constructor() { 
        super("jobs", JobsFieldIdMapping); 
        this.get = new GetAPI_Jobs();
    }
}

class DealsAPI extends API_Base<Deals_AT> {
    get: GetAPI_Deals;

    constructor() { 
        super("deals", DealsFieldIdMapping); 
        this.get = new GetAPI_Deals();
    }
}

class CompaniesAPI extends API_Base<Companies_AT> {
    constructor() { super("companies", CompaniesFieldIdMapping); }
}

class GrowersAPI extends API_Base<Growers_AT> {
    get: GetAPI_Growers;

    constructor() { 
        super("growers", GrowersFieldIdMapping); 
        this.get = new GetAPI_Growers();
    }
}
// #endregion

// #region EXPORTS
export const Users = new UsersAPI();
export const Jobs = new JobsAPI();
export const Deals = new DealsAPI();
export const Companies = new CompaniesAPI();
export const Growers = new GrowersAPI();
// #endregion