import { ATRecord, Branches, Companies, Contacts, Deals, Growers, IAirtableAttachment, JobReady, Jobs, create, createInstance, getField, getFieldId, select } from '@rogoag/airtable';
import { checkIfS3FileExists, downloadFile, upload, urlFor } from './s3_ops';
import axios, { AxiosRequestConfig } from 'axios';
import { setJwtToken, setRefreshToken } from './jwt';
import { RogoPortalUser, UserContext } from '../hooks/UserContext';
import { CustomerReadyPriority, PortalConfig, JobsPriority, ViewDefinition } from '../types';
import { SHP } from '../geojson_converters/shp';
import { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import { inflate } from 'pako';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import * as utils from '../utils';
import { env } from '../config';
import * as API from '../API';

//Airtable Imports
const airtable = createInstance(
    env.airtableAPIKey!,
    env.airtableBaseID!,
);

const JobsReadyTable = airtable.table<JobReady>('Job Ready');
const BranchesTable = airtable.table<Branches>('Branches');

const AXIOS_CONFIG: AxiosRequestConfig<any> = {
    headers: { Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}` },
    responseType: 'blob', // Expecting a binary response
};

export const hasAttachments = (field: any): field is IAirtableAttachment[] => {
    console.log(field);
    // null/undefined field value 
    if (!field) return false;

    // check if the field is an array
    if (!Array.isArray(field)) return false;

    // make sure we have at least one element in the array
    if (!field.length) return false;

    // make sure this is an object, not a basic type
    if (typeof field[0] !== "object") return false;

    // make sure the first element has a url property
    if (!("url" in field[0])) return false;

    // make sure the first elements url property is truthy
    if (!field[0]["url"]) return false;

    return true;
}

export async function resetPassword(email: string) {
    try {
        const response = await axios.post(`${env.apiURL}/auth/reset-password`, { email }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            //console.error(error.response.data);
            throw new Error(error.response.data.detail || 'Error resetting password');
        } else {
            //console.error(error);
            throw new Error('Network or other error');
        }
    }
}

export async function validateNewPasswordToken(token: string) {
    try {
        const response = await axios.post(`${env.apiURL}/auth/validate-token`, { "token": token }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            //console.error(error.response.data);
            throw new Error(error.response.data.detail || 'Error validating token');
        } else {
            //console.error(error);
            throw new Error('Network or other error');
        }
    }
}

export async function updatePassword({ username = '', password = '', token = ''} = {}) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);
    params.append('scope', '');
    params.append('client_id', '');
    params.append('client_secret', token);
    
    const response = await axios.post<{ access_token: string, token_type: string }>(`${env.apiURL}/auth/update-password`, params);
    return response.status === 200;
}

export async function registerUser({ username = '', password = '', firstName = '', lastName = ''} = {}) {
    try{
        let params = { 
            "username": username, 
            "password": password,
            "firstName": firstName,
            "lastName": lastName
        }
        const response = await axios.post<{ access_token: string, token_type: string }>(`${env.apiURL}/auth/register`, params);

        const token = response.data.access_token;
        if (token == "new_user"){
            const user = {};
            return { ...user, token, refreshToken: '' };
        } else {
            localStorage.setItem('rogo_id_token', token);
    
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
            const user = await getCurrentUser();
            console.log(user);
    
            if (!user) {
                Sentry.captureMessage(`User with username ${username} not found`);
                throw new Error(`User ${username} not found`);
            }
    
            setJwtToken(token);
            setRefreshToken('');
    
            return { ...user, token, refreshToken: '' };
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            //console.error(error.response.data);
            throw new Error(error.response.data.detail || 'Error');
        } else {
            //console.error(error);
            throw new Error('Network or other error');
        }
    }

}

interface JobReadyArgs {
    fieldPriority: CustomerReadyPriority;
    submissionNotes: string;
    selectedJobs: ATRecord<Jobs>[];
}
export async function createJobReady({ fieldPriority, submissionNotes, selectedJobs }: JobReadyArgs) {
    if (selectedJobs) {
        if (selectedJobs.length > 0) {
            const jobsIds = selectedJobs.map(job => job.id);
            // @ts-ignore
            const jobReadyForm: ATRecord<JobReady> = {
                table: "Job Ready",
                [getFieldId("Job Ready", "Name")]: "",
                [getFieldId("Job Ready", "Jobs")]: jobsIds,
                [getFieldId("Job Ready", "Customer Priority")]: fieldPriority,
                [getFieldId("Job Ready", "Field Ready Submission Notes")]: submissionNotes,
                [getFieldId("Job Ready", "Submitter")]: "",
            } as const;
        
            console.log(jobReadyForm);
        
            for (let i = 0; i < selectedJobs.length; i++) { // need to test this
                const job = selectedJobs[i];
                if (!!job['Job Ready Form']) {
                    Sentry.captureMessage(`Job ${job.id} already marked as ready, but an additional Job Ready form is being attached`, "info");
                }
            }
        
            await create(JobsReadyTable, [jobReadyForm]);
        } else {
            toast.error('No jobs selected to mark as ready');
            throw new Error(`selectedJobs.length == 0`);
        }
    } else {
        toast.error('No jobs selected to mark as ready');
        throw new Error(`selectedJobs == undefined`);
    }
}

export async function login({ username = '', password = '', token = '' } = {}) {
    try {
        if (!token) {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('username', username);
            params.append('password', password);
            params.append('scope', '');
            params.append('client_id', '');
            params.append('client_secret', '');
            const response = await axios.post<{ access_token: string, token_type: string }>(`${env.apiURL}/auth/token`, params);
            token = response.data.access_token;
            localStorage.setItem('rogo_id_token', token);
        }
    
        // if succeeded, we will set this token as the default
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
        // first do the user call with the token attached manually
        const user = await getCurrentUser();
    
        if (!user) {
            Sentry.captureMessage(`User with username ${username} not found`);
            toast.error(`User ${username} not found`);
        }
    
        setJwtToken(token);
        setRefreshToken('');
    
        return { ...user, token, refreshToken: '' };
    } catch(error: Error | any) {
        handleError(error);
    }

    function handleError(error: any) {
        utils.logErrorToSentry(username, error, utils.ErrorSource.AirTableOps);
        setCustomerErrorMessage(error);
    }

    function setCustomerErrorMessage(error: any) {
        switch (error.message) {
            case "Incorrect username or password":
                toast.error("Incorrect username or password");
                utils.logMessageToSentry(username, `Incorrect username or password for user ${username}`)
            default:
                toast.error(`${error.message}. Contact software@rogoag.com`); break;
        }
    }
}

export async function proagricaLoggedIn() {
    const user = await getCurrentUser();
    return !!user.proagrica_auth;
}

export async function setProagricaState(nonce: string) {
    await axios.put(`${env.apiURL}/auth/proagrica?nonce=${nonce}`, undefined, AXIOS_CONFIG);
}

// TODO this all needs to go through the API...
export async function getUsersData(user?: string | ATRecord<Contacts>) {
    // TODO handle undefined better...
    const userRecord = typeof user === 'string' ? await API.Users.get.byId(user) : user;
    if (!userRecord) {
        Sentry.captureMessage(`User not found with ID ${typeof user === 'string' ? user : user?.id} (${typeof user})`);
        const identifier = typeof user === 'string' ? user : user?.id;
        throw new Error(`User ${identifier || "Undefined"} not found`);
    }
    const companyIDs = getField(userRecord, 'Company', []).concat(getField(userRecord, 'DM for', []));

    if (companyIDs.length === 0) throw new Error(`User must be linked to at least one company`);

    const companies = await API.Companies.get.byIds(companyIDs);
    const allCompanies: ATRecord<Companies>[] = companies;
    const usersCompany = allCompanies[0];

    // TODO if superuser (ROGO org user...) then get all companies?
    const userCompanyName = getField(usersCompany, 'Client Clean');
    if (!userCompanyName) throw new Error('Company name not found');

    const importOptions: PortalConfig[] = ['Single Job Upload']

    // Handsoff Files: Rogo Form + Files Retrieve
    const atConfigItems: string[] = getField(usersCompany, 'Portal Configuration Options') || [];
    if (atConfigItems.includes('Import: CSV + SHP')) {
        importOptions.push('CSV + SHP Upload');
    }
    
    // @ts-ignore
    const companySoftwareActive: string[] = getField(usersCompany, 'Softwares Active');
    const hasProAgrica = 
        getField(userRecord, "PAuth") || 
        (companySoftwareActive.length > 0 && companySoftwareActive[0]?.toLowerCase().includes("proagrica")) ||
        atConfigItems.includes("Import: ProAgrica");

    if (userCompanyName.toLowerCase() === 'rogo ag') {
        importOptions.push(
            'CSV + SHP Upload', 
            'ProAgrica/Agx Import'
        );
        var activeCompanies = await API.Companies.get.byViewId("viwW2z0QNYIQJtkN3"); // "Active Companies - Deals OR Customer Status Current"
        allCompanies.push(...activeCompanies);
    } else if (hasProAgrica) {
        importOptions.push('ProAgrica/Agx Import');
    }

    // remove duplicates based on airtable ID
    const uniqueCompanies = allCompanies.filter((company, index, self) =>
        index === self.findIndex((t) => (
            t.id === company.id
        ))
    );

    // return the primary companies deals by default
    const dealIds = getField(usersCompany, '#Deals') || [];
    if (dealIds.length === 0) throw new Error('Company must have at least one deal');
    const deals = await API.Deals.get.activeAndPrimary(dealIds);
    
    return [userRecord, uniqueCompanies, deals, importOptions, usersCompany] as const;
}

// TODO this all needs to go through the API...
export async function getBranches(company: string | ATRecord<Companies>) {
    const companyRecord = typeof company === 'string' ? await API.Companies.get.byId(company) : company;
    const branchIds = getField(companyRecord, 'Locations/Branches');
    if (!branchIds || branchIds.length === 0) return [];
    const branches = await select(BranchesTable, {
        filterByFormula: `OR(${branchIds.map((id) => `RECORD_ID()='${id}'`).join(',')})`,
        returnFieldsByFieldId: true,
    });
    return branches;
}

export async function getDealsFromClient(client: string | ATRecord<Companies>) {
    const clientRecord = typeof client === 'string' ? await API.Companies.get.byId(client) : client;
    const dealIds = getField(clientRecord, '#Deals') || [];
    if (dealIds.length === 0) return [];
    const deals = await API.Deals.get.activeAndPrimary(dealIds);
    return deals;
}

export async function downloadATAttachment<T=ArrayBuffer|Blob|string>(
    recordID: string, 
    attachments: IAirtableAttachment[],
    responseType: 'arraybuffer' | 'blob' | 'json' | 'text'
) {
    try {
        if (!attachments || !attachments.length) {
            return;
        }
    
        const attachment = attachments[0];
    
        // first try to form an S3 url
        let filename = attachment.filename;
        // filename will be in two different formats
        // {recordID}/{filename} or {filename}
        if (filename.startsWith(`${recordID}/`)) {
            // remove the recordID from the filename
            filename = filename.split('/').slice(1).join('/');
        }
    
        const s3Url = urlFor(filename);
        if (await checkIfS3FileExists(s3Url)) {
            return await downloadFile<T>(s3Url, responseType);
        }
    
        // if that fails, we will try to download the attachment directly
        return await downloadFile<T>(attachment.url, responseType);
    } catch (ex) {
        console.error(ex);
    }
}

export async function getBoundaryForJob(job: ATRecord<Jobs>) {
    const bndGeoJSON = await downloadATAttachment<string>(job.id, getField(job, 'Bnd GeoJSON'), 'json');

    if (bndGeoJSON) {
        // console.count('Got boundary from GeoJSON');
        const bndGeoJSONParsed = bndGeoJSON as unknown;
        return bndGeoJSON as unknown as FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>;
    }

    const bndShp = await downloadATAttachment<ArrayBuffer>(job.id, getField(job, 'Bnd Shp'), 'arraybuffer');

    if (bndShp) {
        try {
            // console.log('Got boundary from SHP');
            return await SHP.toGeoJSON(Buffer.from(bndShp)) as unknown as FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>;
        } catch (ex) {
            console.error(ex);
        }
    }
}

export async function getPointsForJob(job: ATRecord<Jobs>) {
    const exePtsShp = await downloadATAttachment<ArrayBuffer>(job.id, getField(job, 'Exe Pts Shp'), 'arraybuffer');
    if (exePtsShp) {
        try {
            return await SHP.toGeoJSON(Buffer.from(exePtsShp)) as unknown as FeatureCollection<Polygon>;
        } catch (ex) {
            console.error(ex);
        }
    }

    const ptsShp = await downloadATAttachment<ArrayBuffer>(job.id, getField(job, 'Pts Shp'), 'arraybuffer');
    if (ptsShp) {
        try {
            return await SHP.toGeoJSON(Buffer.from(ptsShp)) as unknown as FeatureCollection<Polygon>;
        } catch (ex) {
            console.error(ex);
        }
    }
}

export async function getCurrentUser() {
    // call api/users/me
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}`
    }
    const user = await axios.get<RogoPortalUser>(`${env.apiURL}/auth/me?use_cache=false&environment_name=${env.environmentName}&app_version=${env.appVersion}`, { headers });
    // TODO if we are going to store these here we should also use expiring cookies 
    localStorage.setItem('proagrica_access_token', user.data.proagrica_auth?.access_token || '');
    localStorage.setItem('proagrica_refresh_token', user.data.proagrica_auth?.refresh_token || '');
    localStorage.setItem('proagrica_auth', JSON.stringify(user.data.proagrica_auth));
    return { ...user.data, token: localStorage.getItem('rogo_id_token')! };
}
