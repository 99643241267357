import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getBoundaryForJob, getPointsForJob } from '../api/airtable_ops'; 
import { CircularProgress, Typography, Grid, Box, Button } from '@mui/material';
import { ATRecord, getField, IAirtableAttachment, Jobs } from '@rogoag/airtable';
import GoogleMapComponent from './GoogleMapComponent';
import { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import PortalTooltip from './PortalTooltip';
import { Download } from '@mui/icons-material';
import * as API from '../API';

const JobDetails = () => {
    const { record_id } = useParams<string>();
    const [job, setJob] = useState<ATRecord<Jobs>>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [boundaries, setBoundaries] = useState<Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>>();
    const [points, setPoints] = useState<Record<string, FeatureCollection<Point>>>();

    useEffect(() => {
        // TODO: update fetch job for any job
        const fetchJob = async () => {
            try {
                const job = await API.Jobs.get.byId(record_id || '');
                console.log("job", job);
                (job == null) ? setJob(undefined) : setJob(job);

                if (job) {
                    const displayedBoundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};
                    const displayedPoints: Record<string, FeatureCollection<Point>> = {};

                    const [boundary, points] = await Promise.all([
                        getBoundaryForJob(job),
                        getPointsForJob(job)
                    ]);

                    if (boundary) {
                        displayedBoundaries[job.id] = boundary;
                    }

                    if (points) {
                        // @ts-ignore
                        displayedPoints[job.id] = points;
                    }

                    setBoundaries(displayedBoundaries);
                    setPoints(displayedPoints);
                }
            } catch (error) {
                setError('Failed to fetch job details. Contact software@rogoag.com');
            } finally {
                setLoading(false);
            }
        };

        fetchJob();
    }, [record_id]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !job) {
        return <div>{error ? error :  "other error"}</div>;
    }

    //TODO: Add deals to the job details

    //TODO: Add download functionality
    const renderDownloadButton = (title:string, file: IAirtableAttachment[] | undefined) => (
        <PortalTooltip title={file?.[0]?.filename || 'Not available'}>
            <Button
                color="primary"
                size='small'
                style={{ paddingTop: 0, paddingBottom: 0 }}
                disabled={!file}
            >
                <Download />
            </Button>
        </PortalTooltip>
    );

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Record ID: {record_id}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>

                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Company: <Typography component="span" variant="body1">{getField(job, "Client")}</Typography>
                    </Typography>

                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Deal: <Typography component="span" variant="body1">{getField(job, "Deal")}</Typography>
                    </Typography>

                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Creation Date: <Typography component="span" variant="body1">{getField(job, "Creation Date with Time")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Job Name: <Typography component="span" variant="body2">{getField(job, "Name")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Farm: <Typography component="span" variant="body2">{getField(job, "Farm Name Clean")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Field: <Typography component="span" variant="body2">{getField(job, "Field Name Clean")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Grower: <Typography component="span" variant="body2">{getField(job, "Grower")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Acres: <Typography component="span" variant="body2">{getField(job, "Boundary Acres")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Edits: <Typography component="span" variant="body2">{getField(job, "Edits by Customer")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        File Last Edited: <Typography component="span" variant="body2">{getField(job, "Edit Files Complete Date (latest)")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Submitter: <Typography component="span" variant="body2">{getField(job, "Submitter")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Approved Billing: <Typography component="span" variant="body2">{getField(job, "$ Approved Billing?")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Job Status: <Typography component="span" variant="body2">{getField(job, "Job Status - Simple")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Date Sample: <Typography component="span" variant="body2">{getField(job, "Sample Date")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Drop/Ship Date: <Typography component="span" variant="body2">{getField(job, "Drop/Ship Date")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Test Package: <Typography component="span" variant="body2">{getField(job, "Test Pckg - Display")}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Lab Sent Results: <Typography component="span" variant="body2">{getField(job, "Lab Results Sent Date") as string}</Typography>
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <GoogleMapComponent boundaries={boundaries} points={points} height={"60vh"}/>
                </Grid>
                <Grid item xs={12}>
                    <span>
                        <Typography variant="h5">Downloads</Typography>
                        <Typography variant="body1">Download Boundary: {renderDownloadButton('Boundary', job['Bnd Shp'])}</Typography>
                        <Typography variant="body1">Download Points: {renderDownloadButton('Points', job['Pts Shp'])}</Typography>
                        <Typography variant="body1">Download Sample Zones: {renderDownloadButton('Sample Zones', job['Sample Zones Shp'])}</Typography>
                        <Typography variant="body1">Download Executed Points: {renderDownloadButton('Executed Points', job['Exe Pts Shp'])}</Typography>
                        <Typography variant="body1">Download Executed Boundary: {renderDownloadButton('Executed Boundary', job['Exe Bnd Shp'])}</Typography>
                        <Typography variant="body1">Download Executed Points + Boundary: {renderDownloadButton('Executed Points + Boundary', job['Exe Pts + Bnd Shps'])}</Typography>
                    </span>
                </Grid>
            </Grid>
        </Box>
    );
};

export default JobDetails;