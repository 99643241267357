import axios from "axios";
import { IField, IGrower, IClient, IJob, IDeal, IUser, JobCreationData } from "../types";
import { IJobDataService } from "./job_data_ops";
import { AgVanceBoundary, AgVanceEventDetails, EventSummary, AgVanceField, AgVanceUser } from "./agvance_ops";
import { Jobs } from "@rogoag/airtable";
import { uploadFilesToS3 } from "../utils";
import JSZip from "jszip";
import * as Sentry from '@sentry/react';
import { env } from "../config";

interface AgVanceJobImport {
    user: AgVanceUser;
    fields: {
        [fieldGuid: string]: {
            field: AgVanceField;
            boundary: AgVanceBoundary;
            event_details: AgVanceEventDetails[];
            event_summaries: EventSummary[];
        }
    }
}

export async function jobsToBulkUpload(jobs: JobCreationData[]) {
    const zip = new JSZip();

    const csvRows = ['3rd Party Reference ID\t3rd Party Lab Test Package ID\tBoundary Change Type\tBranch / Location #form\tDepth from Form\tDeal\tEvent ID\tFarm from Form\tField from Form\tField ID\tField Notes\tField Ready at Submission?\tGrower Cell Phone Number\tGrower from Form\tGrower Link\tLab from Form\tLab Instructions\tPriority OVRD\tRegular Lab Submission Code\tRetest? (Pts Submitted by Customer)\tSubmission Notes\tSubmitter Email\tSubmitter\tJob Flags']
    for (const job of jobs) {
        const row = [
            job.externalId,
            job.labPackage,
            job.collectBoundary ? 'Major' : '',
            job.branchId,
            job.depth,
            job.deal,
            job.syncCode,
            job.farmName,
            job.fieldName,
            job.fieldGuid,
            job.readyToSampleNow,
            job.growerCell,
            job.growerName,
            job.growerRecordId,
            job.labFromForm,
            job.specialLabInstructions,
            job.fieldPriority,
            job.labPresubmissionCode,
            job.pointsAttached,
            job.submissionNotes,
            job.submitterEmail,
            job.submitterName,
            job.jobFlags,
        ].join(',');

        csvRows.push(row);

        const files: File[] = [];
        if (job.boundary) files.push(...Array.from(job.boundary));
        if (job.zones) files.push(...Array.from(job.zones));
        if (job.points) files.push(...Array.from(job.points));
        if (job.optionalFieldAttachments) files.push(...Array.from(job.optionalFieldAttachments));

        const jobZip = zip.folder(job.externalId);
        for (const file of files) {
            jobZip?.file(file.name, file);
        }
    }

    zip.file('jobs.csv', csvRows.join('\n'));

    return await zip.generateAsync({ type: 'blob' });
}

export async function submitJobs(jobs: JobCreationData[]) {
    const rogo_access_token = localStorage.getItem('rogo_id_token');
    const jobsToSubmit = await Promise.all(jobs.map(async job => {
        const files: File[] = [];
        if (job.boundary) files.push(...Array.from(job.boundary));
        if (job.zones) files.push(...Array.from(job.zones));
        if (job.points) files.push(...Array.from(job.points));
        // @ts-ignore
        // const rogoParsedJob = new JobLoader(files);
        // console.log(job);
        const newJob: Partial<Jobs> = {
            // for agvance, this is the event guid
            '3rd Party Reference ID': job.externalId,
            '3rd Party Lab Test Package ID': job.labPackage,
            'Bnd Shp': await uploadFilesToS3(job.boundary), // fileListToAirtableAttachment(job.boundary),
            'Boundary Change Type': job.collectBoundary ? 'Major' : undefined,
            'Branch / Location #form': job.branchId ? [job.branchId] : [],
            'Depth from Form': job.depth.toString(),
            'Deal': job.deal ? [job.deal] : [],
            'Event ID': job.syncCode,
            'Farm from Form': job.farmName,
            'Field from Form': job.fieldName,
            'Field ID': job.fieldGuid,
            'Field Notes': await uploadFilesToS3(job.optionalFieldAttachments), // fileListToAirtableAttachment(job.optionalFieldAttachments),
            'Field Ready at Submission?': job.readyToSampleNow,
            'Grid from Form': job.gridSize,
            'Grower Cell Phone Number': job.growerCell,
            'Grower from Form': job.growerName,
            'Grower Link': job.growerRecordId ? [job.growerRecordId] : [],
            'Lab from Form': job.labFromForm,
            'Lab Instructions': job.specialLabInstructions,
            'Priority OVRD': job.fieldPriority,
            'Pts Shp': await uploadFilesToS3(job.points), // fileListToAirtableAttachment(job.points)
            'Regular Lab Submission Code': job.labPresubmissionCode,
            'Retest? (Pts Submitted by Customer)': job.pointsAttached,
            'Submission Notes': job.submissionNotes,
            'Submitter Email': job.submitterEmail,
            'Submitter': job.submitterName,
            'Job Flags': job.jobFlags,
            'Sample Zones Shp': await uploadFilesToS3(job.zones), // fileListToAirtableAttachment(job.zones),
            'Testing?': job.testing ? 'Test' : undefined,
        }
        return newJob;
    }));
    // perform validation with Rogo Job Checker
    
    console.log(jobsToSubmit);
    const result = await axios.post(`${env.apiURL}/jobs`, jobsToSubmit, { 
        headers: {
            Authorization: `Bearer ${rogo_access_token}`,
        }
    });
    
    //return newRecord.id;
    console.log(result);
}

export async function importAgVanceData(): Promise<AgVanceJobImport> {
    const rogo_access_token = localStorage.getItem('rogo_id_token');
    const agvance_token = localStorage.getItem('agvance_id_token');
    const response = await axios.get(`${env.apiURL}/agvance/jobs/import?agvance_jwt=${agvance_token}`, {
        headers: {
            Authorization: `Bearer ${rogo_access_token}`,
        }
    });

    return response.data;
}

export class RogoDataService implements IJobDataService {
    private accessToken: string = '';
    private refreshToken: string = '';

    async authenticate(username: string, password: string): Promise<boolean> {
        if (!this.accessToken) {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('username', username);
            params.append('password', password);
            params.append('scope', '');
            params.append('client_id', '');
            params.append('client_secret', '');
            const response = await axios.post<{ access_token: string, token_type: string }>(`${env.apiURL}/auth/token`, params);
            this.accessToken = response.data.access_token;
        }

        // TODO check auth token for expiration and refresh
        return true;
    }

    async getUser(): Promise<IUser> {
        throw new Error("Method not implemented.");
    }
    getField(fieldId: string): Promise<IField> {
        throw new Error("Method not implemented.");
    }
    getFields(grower: IGrower): Promise<IField[]> {
        throw new Error("Method not implemented.");
    }
    getGrower(growerId: string): Promise<IGrower> {
        throw new Error("Method not implemented.");
    }
    getGrowers(client: IClient): Promise<IGrower[]> {
        throw new Error("Method not implemented.");
    }
    getJob(jobId: string): Promise<IJob> {
        throw new Error("Method not implemented.");
    }
    getJobs(): Promise<IJob[]> {
        throw new Error("Method not implemented.");
    }
    getDeals(client: IClient): Promise<IDeal[]> {
        throw new Error("Method not implemented.");
    }

}