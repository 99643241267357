import axios from "axios";
import { wktToGeoJSON } from "@terraformer/wkt"
import { BoundaryRecord, Farm, Field, Grower, GrowerFarm, GrowerFarmField, SamplingActivity, SoilSamplingListItem, SoilSamplingPWORecord, SoilSamplingRecord } from "./proagrica_types";
import { Geometry as WKXGeometry } from "wkx";
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUser } from "./airtable_ops";
import { env } from "../config";

export function authenticate(username: string, password: string) {
    // ...
    return true;
}

interface APIParameters {
    endpoint: string;
    // access_token: string | null;
    sync_id: number;
    query_params?: URLSearchParams;
    // proagrica_token: string | null;
}

const RESPONSE_TYPE = "code"

const SCOPES = [
    "sync",
    "openid",
    "profile",
    "referencedata",
    "offline_access"
] as const;

export function getAuthUrl(url: string) {
    // generate random state like a uuid + a timestamp
    // let state: string = uuidv4() + Date.now().valueOf().toString();
    console.log(`Get Auth URL`);
    let nonce: string = `${uuidv4()}.${Date.now().valueOf().toString()}`;
    let state = `${url},${nonce}`

    let authUrl: string =  `${env.agxAuthBaseURL}`;
    authUrl += `/${env.agxAuthEndpoint}`;
    authUrl += `?response_type=${RESPONSE_TYPE}`;
    authUrl += `&client_id=${env.agxClientID}`;
    authUrl += `&redirect_uri=${env.agxRogoRedirectURI}`;
    authUrl += `&scope=${SCOPES.join('%20')}`;
    authUrl += `&state=${state}`;

    console.log(`Auth URL: ${authUrl}`);

    return [authUrl, state, nonce] as const;
}

function getProagricaToken(code: string, state: string) {
    
}

export async function makeRogoProagricaApiCall({ endpoint, query_params, sync_id }: APIParameters) {
    let access_token = localStorage.getItem('rogo_id_token');
    if (!access_token) {
        throw new Error('No access token provided');
    }
    let proagrica_access_token = localStorage.getItem('proagrica_access_token');
    if (!access_token) {
        throw new Error('No access token provided');
    }
    // TODO we shouldn't be passing the refresh token in requests but this is just temporary
    let proagrica_refresh_token = localStorage.getItem('proagrica_refresh_token');
    if (!proagrica_refresh_token) {
        throw new Error('No access token provided');
    }
    let url = `${env.apiURL}/proagrica/proxy/${sync_id}/${endpoint}`;
    if (query_params) {
        url += `?${new URLSearchParams(query_params).toString()}`;
    }
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Proagrica-Token': proagrica_access_token,
            'Proagrica-Refresh-Token': proagrica_refresh_token,
        }
    });

    return response.data;
}

export interface JwtHeader {
    alg: string;
    kid: string;
    typ: string;
    x5t: string;
}

export interface JwtPayload {
    nbf: number;
    exp: number;
    iss: string;
    aud: string[];
    nonce: string;
    iat: number;
    at_hash: string;
    s_hash: string;
    sid: string;
    sub: string;
    auth_time: number;
    idp: string;
    family_name: string;
    given_name: string;
    amr: string[];
    syncids: string;
    scope: string[];
}

export interface DecodedJWT {
    header: JwtHeader;
    payload: JwtPayload;
}

export function decodeJwt(token: string): DecodedJWT {
    const [header, payload] = token.split(".");

    // Decode base64 URL-safe encoded header and payload
    const decodedHeader = atob(header).toString();
    const decodedPayload = atob(payload).toString();

    // Convert to JSON
    const headerJson = JSON.parse(decodedHeader) as JwtHeader;
    const payloadJson = JSON.parse(decodedPayload) as JwtPayload;

    return {
        header: headerJson,
        payload: payloadJson
    };
}


export async function getGrower(grower_uuid: string | null = null, sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `Grower/${grower_uuid}`,
        sync_id,
    }) || []) as Grower;
}
// jFx7Ckb2LrDOVkAPSazHt0sv0heh3A8ic6K0AwnMJfM=

export async function getGrowers(sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `Grower/All`,
        sync_id
    }) || []) as Grower[];
}

export async function getFarm(farm_uuid: string, sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `Farm/${farm_uuid}`,
        sync_id
    }) || []) as Farm;
}


export async function getFarmsForGrower(grower_uuid: string, sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `Grower/${grower_uuid}/Farm`,
        sync_id
    }) || []) as GrowerFarm[];
}

export async function getManagementZones(field_uuid: string, sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `Field/${field_uuid}/ManagementZone`,
        sync_id
    }) || []) as any[];
}

export async function getManagementZone(zone_id: string, sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `ManagementZone/${zone_id}`,
        sync_id
    }) || []) as any;
}

export async function getFieldsForGrowerAndFarm(grower_uuid: string, farm_uuid: string, sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `Grower/${grower_uuid}/Farm/${farm_uuid}/Field`,
        sync_id
    }) || []) as GrowerFarmField[];
}

export async function getFields(access_token: string | null = null, sync_id: number) {
    return (await makeRogoProagricaApiCall({
        endpoint: `Field?startTime=2024-01-01T15:49:28Z`,
        sync_id
    }) || []) as Field[];
}


export async function getField(field_uuid: string, sync_id: number) {
    return await makeRogoProagricaApiCall({
        endpoint: `Field/${field_uuid}`,
        sync_id
    }) as Field;
}

export function getJwtSyncIds() {
    const proagrica_access_token = localStorage.getItem('proagrica_access_token');
    if (!proagrica_access_token) {
        throw new Error('No access token provided');
    }

    const decodedToken = decodeJwt(proagrica_access_token);
    const jwtSyncIds = JSON.parse(decodedToken.payload.syncids) as { id: number, p: string}[]
    console.log(jwtSyncIds);
    return jwtSyncIds.map(syncId => syncId.id);
}

export async function refreshProagricaToken() {
    const proagrica_access_token = localStorage.getItem('proagrica_access_token');
    const proagrica_refresh_token = localStorage.getItem('proagrica_refresh_token');
    if (!proagrica_access_token || !proagrica_refresh_token) {
        throw new Error('No access token provided');
    }

    const decodedToken = decodeJwt(proagrica_access_token);
    const remainingSeconds = decodedToken.payload.exp - Math.floor(Date.now() / 1000);
    if (remainingSeconds > 180) {
        return;
    }

    const rogo_access_token = localStorage.getItem('rogo_id_token');
    const response = await axios.post(`${env.apiURL}/proagrica/auth/refresh`, null, {
        headers: {
            'proagrica-token': proagrica_access_token,
            'proagrica-refresh-token': proagrica_refresh_token
        }
    });

    // call get user to force the token to be stored in local storage
    await getCurrentUser();
}

export function boundaryRecordsToGeoJson(records: BoundaryRecord[]) {
    return {
        type: "FeatureCollection",
        features: records.map(record => {
            const geoJSON = wktToGeoJSON(record.WKT);
            return {
                geometry: geoJSON,
                type: "Feature",
                properties: {
                    measure: record.Measure.Value
                }
            }
        })
    } as GeoJSON.FeatureCollection;
}

export function samplingRecordsToGeoJson(records: SoilSamplingRecord[] | SoilSamplingPWORecord[]) {
    // @ts-ignore
    return {
        type: "FeatureCollection",
        features: records.map(record => {
            const geoJSONGeometry = WKXGeometry.parse(
                "WKB" in record
                    ? Buffer.from(record.WKB)
                    : record.WKT
            ).toGeoJSON();
            return {
                geometry: geoJSONGeometry,
                type: "Feature",
                properties: {
                    //measure: record.Measure.Value
                }
            }
        })
    } as GeoJSON.FeatureCollection;
}

export async function getSamplingItems(field_uuid: string, sync_id: number) {
    const samplingTypes = [
        // 'SoilSamplingPlan',
        'SoilSamplingRecommendation',
        // 'SoilSamplingWorkOrder',
        'SoilSamplingApplication',
    ]
    const samplingItems = await Promise.all(samplingTypes.map(async samplingType => {
        const samplingActivies = (await makeRogoProagricaApiCall({
            endpoint: `Field/${field_uuid}/${samplingType}`,
            sync_id
        }) || []) as SoilSamplingListItem[];
        const undeletedSamplingItems = samplingActivies.filter(activity => !activity.IsDeleted);
        return await Promise.all(undeletedSamplingItems.map(async activity => {
            return await makeRogoProagricaApiCall({
                endpoint: `${samplingType}/${activity["ID"]}`,
                sync_id
            });
        }));
    }));
    return samplingItems.flat(2) as SamplingActivity[];
}

export async function proagricaToJobData() {
    const rogo_access_token = localStorage.getItem('rogo_id_token');
    // const growers = await getGrowers(rogo_access_token);
    // console.log(growers.length);
    // const fields = await getFields(rogo_access_token);
    // console.log(fields);
    // for (const grower of growers) {
    //     console.log(`${JSON.stringify(grower)}`);
    //     const farms = await getFarmsForGrower(grower["ID"]);
    //     for (const farm of farms) {
    //         console.log(`-- ${JSON.stringify(farm)}`);
    //         const fields = await getFieldsForGrowerAndFarm(grower["ID"], farm["ID"]);
    //         for (const field of fields) {}
    //     }
    // }
}

// export async function importAgVanceData(): Promise<AgVanceJobImport> {
//     // call ROGO_DATA_URL/jobs/import/agvance with the id token
//     const rogo_access_token = localStorage.getItem('rogo_id_token');
//     const agvance_token = localStorage.getItem('agvance_id_token');
//     const response = await axios.get(`${ROGO_DATA_URL}/jobs/import/agvance?agvance_jwt=${agvance_token}`, {
//         headers: {
//             Authorization: `Bearer ${rogo_access_token}`,
//         }
//     });

//     return response.data;
// }