export const env = {
    airtableAPIKey: import.meta.env.VITE_AIRTABLE_API_KEY,
    airtableBaseID: import.meta.env.VITE_AIRTABLE_BASE_ID,
    awsAccessKeyID: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
    awsSecretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
    buildDate: import.meta.env.VITE_BUILD_DATE,
    appVersion: import.meta.env.VITE_APP_VERSION,
    apiURL: import.meta.env.VITE_ROGO_API_URL,
    agxAuthBaseURL: import.meta.env.VITE_AGX_AUTH_BASE_URL,
    agxAuthEndpoint: import.meta.env.VITE_AGX_AUTH_ENDPOINT,
    agxClientID: import.meta.env.VITE_AGX_CLIENT_ID,
    agxRogoRedirectURI: import.meta.env.VITE_AGX_ROGO_REDIRECT_URI,
    environmentName: import.meta.env.VITE_ENVIRONMENT_NAME,
    enableSentry: import.meta.env.VITE_ENABLE_SENTRY,
};